import * as Yup from 'yup';

import { ServiceChooseCarCenterFormFields } from './types';

export const initialServiceChooseCarCenterFormValues: ServiceChooseCarCenterFormFields =
  {
    dealer: 0,
    to: '',
    recommended: false,
    date: '',
    time: '',
    comment: '',
  };

export const validationServiceChooseCarCenterFormSchema: Yup.SchemaOf<ServiceChooseCarCenterFormFields> =
  Yup.object().shape({
    dealer: Yup.number()
      .required('Выберите автоцентр')
      .min(1, 'Выберите автоцентр'),
    to: Yup.mixed().when((value) =>
      typeof value === 'string' ? Yup.string() : Yup.number()
    ),
    recommended: Yup.boolean(),
    date: Yup.string().required('Выберите дату'),
    time: Yup.string(),
    comment: Yup.string(),
  });
