import * as Yup from 'yup';

import {
  ServiceChooseCarAutoFormFields,
  ServiceChooseCarByHandFormFields,
  ServiceChooseCarFormFields,
} from './types';

export const initialServiceChooseCarFormValues: ServiceChooseCarFormFields = {
  carId: 0,
  carBrand: 0,
  carModel: '',
  carYear: 0,
  carVin: '',
  carPlate: '',
  carMileage: 0,
};

export const validationServiceChooseCarAutoFormSchema: Yup.SchemaOf<ServiceChooseCarAutoFormFields> =
  Yup.object().shape({
    carId: Yup.number().required('Выберите машину').min(1, 'Выберите машину'),
  });

export const validationServiceChooseCarByHandFormSchema: Yup.SchemaOf<ServiceChooseCarByHandFormFields> =
  Yup.object().shape({
    carBrand: Yup.number().required('Выберите бренд').min(1, 'Выберите бренд'),
    carModel: Yup.string().required('Введите модель'),
    carYear: Yup.number().required('Выберите год').min(1, 'Выберите год'),
    carVin: Yup.string(),
    carPlate: Yup.string().required('Введите гос. номер'),
    carMileage: Yup.number()
      .required('Введите пробег')
      .min(1, 'Введите пробег'),
  });
