import { StepsProps } from './components/Steps/types';

export const stepsData: StepsProps = {
  title: 'Заполните заявку',
  steps: [
    'Заполните контактные данные',
    'Выберите автомобиль',
    'Выберите автоцентр',
  ],
};
