import React, { useRef, useState } from 'react';
import styled, { css } from 'styled-components';

import { ModalProps } from '@tager/web-components';

import Application from '@/components/modals/DetailedCarModal/components/Application';
import { media } from '@/utils/mixin';
import CloseButton from '@/components/modals/shared/CloseButton';
import { CarProps } from '@/components/modals/DetailedCarModal/components/Application/common.types';
import { useLockBodyScroll } from '@/hooks/useLockBodyScroll';
import { CarsType } from '@/typings/common';

export type ApplicationModalType =
  | 'TEST_DRIVE'
  | 'APPLICATION'
  | 'CUSTOM_PRICE';

type Props = ModalProps<{
  type: ApplicationModalType;
  car: CarProps;
  typeState: CarsType;
}>;

function DetailedCarModal({
  innerProps: { type, car, typeState },
  closeModal,
}: Props) {
  const formInnerRef = useRef<HTMLDivElement>(null);

  const [success, setSuccess] = useState(false);

  useLockBodyScroll();

  return (
    <Component success={success}>
      <CloseButton onClick={closeModal} />
      <Application
        success={success}
        setSuccess={setSuccess}
        closeModal={closeModal}
        formInnerRef={formInnerRef}
        type={type}
        typeState={typeState}
        car={car}
      />
    </Component>
  );
}

const Component = styled.div<{ success: boolean }>`
  position: relative;
  width: calc(100vw - 20px);
  max-width: 674px;
  background: ${(props) => props.theme.white};
  display: flex;
  align-items: flex-start;
  border-radius: 10px;

  ${media.mobile(css`
    max-width: none;
  `)}

  ${({ success }) =>
    success &&
    css`
      align-items: center;
      justify-content: center;
    `}
`;

export default DetailedCarModal;
