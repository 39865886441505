import { formatNumber, isNotNullish, Nullable } from '@tager/web-core';

import { PreviewSvgIconItems } from '@/components/DetailedCarPreviewSpecification/DetailedCarPreviewSpecification.types';
import {
  DetailedCarData,
  SimilarCarsDataParams,
} from '@/services/stock/typings';
import { SEOTemplate } from '@/typings/model';
import { colors, getTheme } from '@/constants/theme';
import {
  CarsStockBattery,
  CarsStockBody,
  CarsStockFuel,
  CarsStockGearbox,
  CarsStockStatusStock,
  CarsStockWheel,
} from '@/services/stock/enums';
import { PartnersCarsTestDriveParams } from '@/services/leads/typings';
import { submitPartnersCarsTestDrive } from '@/services/leads/leads-service';
import { googleEvent, GoogleEventParamType } from '@/utils/events';
import { CarTagsProps } from '@/components/CarTags/CarTags.types';
import { CarViewBody } from '@/components/CarView/types';
import { OptionType } from '@/typings/common';

export function svgIconItem(
  item: CarsStockBody | CarsStockFuel | CarsStockGearbox | CarsStockWheel
) {
  switch (item) {
    case CarsStockBody.Coupe:
      return PreviewSvgIconItems.COUPE;
    case CarsStockBody.Van:
      return PreviewSvgIconItems.VAN;
    case CarsStockBody.Hatchback:
      return PreviewSvgIconItems.HATCHBACK;
    case CarsStockBody.Cabriolet:
      return PreviewSvgIconItems.CABRIOLET;
    case CarsStockBody.Crossover:
      return PreviewSvgIconItems.CROSSOVER;
    case CarsStockBody.LiftBack:
      return PreviewSvgIconItems.LIFT_BACK;
    case CarsStockBody.Minibus:
      return PreviewSvgIconItems.MINIBUS;
    case CarsStockBody.Bus:
      return PreviewSvgIconItems.BUS;
    case CarsStockBody.Minivan:
      return PreviewSvgIconItems.MINIVAN;
    case CarsStockBody.Sedan:
      return PreviewSvgIconItems.SEDAN;
    case CarsStockBody.Chassis:
      return PreviewSvgIconItems.CHASSIS;
    case CarsStockBody.StationWagon:
      return PreviewSvgIconItems.STATION_WAGON;
    case CarsStockBody.Suv:
      return PreviewSvgIconItems.SUV;
    case CarsStockBody.Pickup:
      return PreviewSvgIconItems.PICKUP;
    case CarsStockBody.ShootingBrake:
      return PreviewSvgIconItems.SHOOTING_BRAKE;
    case CarsStockFuel.Electric:
      return PreviewSvgIconItems.ELECTRIC;
    case CarsStockGearbox.Manual:
      return PreviewSvgIconItems.MANUAL;
    case CarsStockGearbox.Robot:
      return PreviewSvgIconItems.ROBOT;
    default:
      return PreviewSvgIconItems.DEFAULT;
  }
}

export function switchNamesItems(
  item:
    | CarsStockFuel
    | CarsStockGearbox
    | CarsStockWheel
    | CarsStockBody
    | CarsStockBattery
    | CarsStockStatusStock
) {
  switch (item) {
    case CarsStockFuel.Petrol:
      return 'Бензин';
    case CarsStockFuel.Diesel:
      return 'Дизель';
    case CarsStockFuel.Gas:
      return 'Газ';
    case CarsStockFuel.Electric:
      return 'Электро ☘️';
    case CarsStockFuel.Hybrid:
      return 'Гибрид';
    case CarsStockGearbox.Manual:
      return 'Механическая';
    case CarsStockGearbox.Automatic:
      return 'Автоматическая';
    case CarsStockGearbox.Robot:
      return 'Робот';
    case CarsStockGearbox.Variator:
      return 'Вариатор';
    case CarsStockGearbox.DSG:
      return 'DSG';
    case CarsStockGearbox.Reductor:
      return 'Редуктор';
    case CarsStockWheel.Front:
      return 'Передний';
    case CarsStockWheel.Back:
      return 'Задний';
    case CarsStockWheel.Full:
      return 'Полный';
    case CarsStockBody.Sedan:
      return 'Седан';
    case CarsStockBody.LiftBack:
      return 'Лифтбек';
    case CarsStockBody.Crossover:
      return 'Кроссовер';
    case CarsStockBody.Minibus:
      return 'Микроавтобус';
    case CarsStockBody.Van:
      return 'Фургон';
    case CarsStockBody.Suv:
      return 'Внедорожник';
    case CarsStockBody.Hatchback:
      return 'Хэтчбек';
    case CarsStockBody.Coupe:
      return 'Купе';
    case CarsStockBody.Pickup:
      return 'Пикап';
    case CarsStockBody.StationWagon:
      return 'Универсал';
    case CarsStockBody.Minivan:
      return 'Минивэн';
    case CarsStockBody.Bus:
      return 'Автобус';
    case CarsStockBody.Chassis:
      return 'Шасси';
    case CarsStockBody.Cabriolet:
      return 'Кабриолет';
    case CarsStockBody.ShootingBrake:
      return 'Шутинг-брейк';
    case CarsStockBattery.LithiumIon:
      return 'Литий-ионная';
    case CarsStockBattery.LithiumIron:
      return 'Литий-железо-фосфатная';
    case CarsStockBattery.LithiumNickel:
      return 'Литий-никель-марганец-кобальт-оксидная';
    case CarsStockBattery.LithiumPolymeric:
      return 'Литий-полимерная';
    case CarsStockBattery.MetalAir:
      return 'Метало-воздушная';
    case CarsStockStatusStock.InStock:
      return 'В наличии';
    case CarsStockStatusStock.InProduction:
      return 'В производстве';
    case CarsStockStatusStock.Preorder:
      return 'Под заказ';
    case CarsStockStatusStock.OnWay:
      return 'В пути';
    default:
      return '';
  }
}

export const convertDetailedCarSEOTemplate = (
  car: Nullable<DetailedCarData>,
  seoTemplate: Nullable<SEOTemplate>
): Nullable<SEOTemplate> => {
  if (!car || !seoTemplate) {
    return null;
  }

  const { title, description, openGraphImage } = seoTemplate;

  const replaceText = (text: Nullable<string>): Nullable<string> => {
    const brand = car.brand.name;
    const model = car.model.name;
    const body = car.params.bodyType
      ? switchNamesItems(car.params.bodyType)
      : '';
    const year = car.year ? String(car.year) : '';
    const volume = car.params.engineCapacity
      ? car.params.engineCapacity.toFixed(1)
      : '';
    const color = car.color && car.color.label ? car.color.label : '';
    const vin = car.vin ? car.vin : String(car.id);
    const fuel = car.params.fuel ? switchNamesItems(car.params.fuel) : '';
    const gearbox = car.params.gearbox
      ? switchNamesItems(car.params.gearbox)
      : '';

    return (
      text
        ?.replaceAll('{{brand}}', brand)
        ?.replaceAll('{{brand|lowercase}}', brand.toLowerCase())
        ?.replaceAll('{{model}}', model)
        ?.replaceAll('{{model|lowercase}}', model.toLowerCase())
        ?.replaceAll('{{body}}', body)
        ?.replaceAll('{{body|lowercase}}', body.toLowerCase())
        ?.replaceAll('{{year}}', year)
        ?.replaceAll('{{volume}}', volume)
        ?.replaceAll('{{color}}', color)
        ?.replaceAll('{{color|lowercase}}', color.toLowerCase())
        ?.replaceAll('{{vin}}', vin)
        ?.replaceAll('{{fuel}}', fuel)
        ?.replaceAll('{{fuel|lowercase}}', fuel.toLowerCase())
        ?.replaceAll('{{gearbox}}', gearbox)
        ?.replaceAll('{{gearbox|lowercase}}', gearbox.toLowerCase())
        ?.split(' ')
        ?.filter((item) => item)
        ?.join(' ') ?? text
    );
  };

  return {
    ...seoTemplate,
    title: replaceText(title),
    description: replaceText(description),
    openGraphImage:
      openGraphImage ?? car.openGraphImage ?? car.mainImage?.preview?.url ?? '',
  };
};

export const getSimilarCarsDataParams = (
  car: DetailedCarData
): SimilarCarsDataParams | undefined => {
  const theme = getTheme();

  return theme !== 'main' ? { brands: String(car.brand.id) } : undefined;
};

export async function handleSubmitPartnersCarsTestDriveForm(
  values: PartnersCarsTestDriveParams,
  event?: { type?: string; params?: GoogleEventParamType }
): Promise<void> {
  try {
    await submitPartnersCarsTestDrive(values).then(() => {
      if (event?.type) {
        googleEvent(event.type, event.params);
      }
    });
  } catch (error: any) {
    return Promise.reject(error);
  }
}

export const getDetailedCarTags = (car: DetailedCarData): CarTagsProps => {
  return {
    tags: [
      car.params.engineCapacity
        ? { title: car.params.engineCapacity.toFixed(1) }
        : undefined,
      car.params.fuel
        ? {
            title: switchNamesItems(car.params.fuel),
            background:
              car.params.fuel === CarsStockFuel.Electric
                ? colors.green200
                : undefined,
          }
        : undefined,
      car.year ? { title: String(car.year) } : undefined,
      car.params.gearbox
        ? { title: switchNamesItems(car.params.gearbox) }
        : undefined,
      car.params.bodyType
        ? { title: switchNamesItems(car.params.bodyType) }
        : undefined,
      car.mileage
        ? { title: formatNumber(car.mileage) + ' км', infoText: 'Пробег' }
        : undefined,
      car.params.battery
        ? {
            title: switchNamesItems(car.params.battery),
          }
        : undefined,
      car.params.batteryCapacity
        ? {
            title: car.params.batteryCapacity + ' кВт/ч',
            infoText: 'Емкость батареи',
          }
        : undefined,
      car.distanceLimit
        ? {
            title: formatNumber(car.distanceLimit) + ' км',
            infoText: 'Запас хода',
          }
        : undefined,
    ].filter(isNotNullish),
    VIN: car.vin ?? '',
  };
};

export function getEventParams(car: DetailedCarData): GoogleEventParamType {
  return {
    car: `${car.brand.name} ${car.model.name}`,
    stockStatus: car.stockStatus ? switchNamesItems(car.stockStatus) : '',
  };
}

export function getCarViewBody(body: CarsStockBody): CarViewBody {
  switch (body) {
    case CarsStockBody.Sedan:
      return 'sedan';
    case CarsStockBody.LiftBack:
      return 'lift_back';
    case CarsStockBody.Crossover:
      return 'crossover';
    case CarsStockBody.Minibus:
      return 'minibus';
    case CarsStockBody.Van:
      return 'van';
    case CarsStockBody.Suv:
      return 'suv';
    case CarsStockBody.Hatchback:
      return 'hatchback';
    case CarsStockBody.Coupe:
      return 'coupe';
    case CarsStockBody.Pickup:
      return 'pickup';
    case CarsStockBody.StationWagon:
      return 'station_wagon';
    case CarsStockBody.Minivan:
      return 'minivan';
    case CarsStockBody.Bus:
      return 'bus';
    case CarsStockBody.Chassis:
      return 'chassis';
    case CarsStockBody.Cabriolet:
      return 'cabriolet';
    case CarsStockBody.ShootingBrake:
      return 'shooting_brake';
    default:
      return 'crossover';
  }
}

export const getDealersOptions = (car: DetailedCarData): OptionType[] => {
  return car.type === 'new' && car.dealers && car.dealers.length !== 0
    ? car.dealers.map((dealer) => ({
        label: `${dealer.address}, ${dealer.name} `,
        value: String(dealer.id),
      }))
    : car.location
    ? [{ label: car.location.address, value: String(car.location.id) }]
    : [];
};
