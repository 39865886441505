import { OptionType } from '@/typings/common';
import { CarsStockSort } from '@/services/stock/enums';

export enum TabsPanelLabels {
  ALL_CARS = 'Все авто',
  NEW_CARS = 'Новые авто',
  AMP_CARS = 'Авто с пробегом',
  ELECTRIC_CARS = 'Электромобили',
  COMMERCIAL_CARS = 'Коммерческие',
}

export enum TabsPanelLinks {
  ALL_CARS = '/cars',
  NEW_CARS = '/cars/new',
  AMP_CARS = '/cars/amp',
  ELECTRIC_CARS = '/cars/electro',
  COMMERCIAL_CARS = '/cars/commercial',
}

export const sortingData: OptionType<CarsStockSort>[] = [
  { value: CarsStockSort.Date, label: 'дате поступления' },
  { value: CarsStockSort.Popular, label: 'популярности' },
  {
    value: CarsStockSort.PriceAsc,
    label: 'цене ↑',
  },
  { value: CarsStockSort.PriceDesc, label: 'цене ↓' },
  {
    value: CarsStockSort.MileageAsc,
    label: 'пробегу ↑',
  },
  { value: CarsStockSort.MileageDesc, label: 'пробегу ↓' },
];

export const pageLimit = 35;

export const skeletonCardsLimit = 36;
