import { ApiService } from '@tager/web-core';

import {
  ServiceBrandLandingResponseType,
  ServiceBrandServiceLandingResponseType,
  ServiceBrandsResponseType,
  ServiceDealersResponseType,
  ServiceSingleLandingResponseType,
} from './typings';

const serviceRequest = new ApiService({
  baseUrl: {
    csr: process.env.NEXT_PUBLIC_SERVICE_URL,
    ssr: process.env.NEXT_PUBLIC_SERVICE_URL,
  },
}).getRequest();

export function getServiceDealersData(): Promise<ServiceDealersResponseType[]> {
  return serviceRequest.get({ path: `/dealers` });
}

export function getServiceBrandsData(): Promise<ServiceBrandsResponseType[]> {
  return serviceRequest.get({ path: `/brands` });
}

export function getServiceSingleLandingData(
  serviceAlias: string[]
): Promise<ServiceSingleLandingResponseType> {
  return serviceRequest.get({
    path: `/service/landing`,
    params: { serviceAlias: serviceAlias.join(',') },
  });
}

export function getServiceBrandLandingData(
  brandAlias: string
): Promise<ServiceBrandLandingResponseType> {
  return serviceRequest.get({ path: `/brand/${brandAlias}/landing` });
}

export function getServiceBrandServiceLandingData(
  brandAlias: string,
  serviceAlias: string[]
): Promise<ServiceBrandServiceLandingResponseType> {
  return serviceRequest.get({
    path: `/brand/service/${brandAlias}/landing`,
    params: { serviceAlias: serviceAlias.join(',') },
  });
}
