import { ApiService } from '@tager/web-core';

import { CarsStockType } from '@/typings/common';

import {
  ModelConfigurationsPageData,
  ModelPageData,
  CatalogFiltersData,
  CatalogCar,
  CatalogStockBrandAndModelsResponse,
} from './typings';

const request = new ApiService({
  baseUrl: {
    csr: process.env.NEXT_PUBLIC_CATALOG_SERVICE_URL,
    ssr: process.env.NEXT_PUBLIC_CATALOG_SERVICE_URL,
  },
}).getRequest();

export function getModelPageData(generationId: number): Promise<ModelPageData> {
  return request.get({ path: `/generations/${generationId}/landing` });
}

export function getModelPageConfigurationsData(
  generationId: number
): Promise<ModelConfigurationsPageData> {
  return request.get({ path: `/generations/${generationId}/configurations` });
}

/** Catalog Filter **/

export function loadCatalogFiltersData(): Promise<CatalogFiltersData> {
  return request.get({
    path: '/filters',
  });
}

export function loadCatalogItemsData(): Promise<CatalogCar[]> {
  return request.get({
    path: '/items',
  });
}

export function getCatalogStockBrandsAndModels(
  carType: CarsStockType
): Promise<CatalogStockBrandAndModelsResponse[]> {
  return request.get({
    path: carType === 'all' ? `/stock` : `/stock/${carType}`,
  });
}
