import React from 'react';
import Router from 'next/router';
import { cloneDeep } from 'lodash';

import { formatNumber, isNotNullish, Nullable } from '@tager/web-core';
import { ThumbnailType } from '@tager/web-modules';

import {
  CarsStockType,
  CarsType,
  OpenModalType,
  OptionType,
  RangeOptionType,
  RangeVariantType,
} from '@/typings/common';
import { CatalogStockBrandAndModelsResponse } from '@/services/catalog/typings';
import {
  CarsStockFiltersParams,
  CarsStockFiltersParamsResponse,
  CarStockItemResponse,
  SelectedFiltersCatalogPayload,
  SelectedFiltersPayload,
} from '@/services/stock/typings';
import { switchNamesItems } from '@/modules/DetailedCar/DetailedCar.helper';
import DetailedCarModal from '@/components/modals/DetailedCarModal';
import { StyledOverlay } from '@/components/modals/shared/StyledOverlay';
import { StoreDispatch } from '@/store/store';
import {
  deleteCarBrands,
  deleteCarComplectations,
  deleteCarGearbox,
  deleteCarModels,
  SelectedCarsType,
  SelectedFiltersType,
  setBrandsAndModelsSuccess,
  setCarApproved,
  setCarAvailable,
  setCarBattery,
  setCarBatteryCapacity,
  setCarBodies,
  setCarBrand,
  setCarBrandsArray,
  setCarClearance,
  setCarColors,
  setCarComplectation,
  setCarComplectationsArray,
  setCarDoors,
  setCarElectricEngines,
  setCarEngineCapacity,
  setCarEnginePower,
  setCarEngineTypes,
  setCarGearbox,
  setCarGearboxArray,
  setCarGrades,
  setCarGuarantee,
  setCarLocations,
  setCarMileage,
  setCarModel,
  setCarModelsArray,
  setCarOnStock,
  setCarPartnerCar,
  setCarPowerReserve,
  setCarPrice,
  setCarSeats,
  setCarSuperPrice,
  setCarVat,
  setCarWheel,
  setCarYear,
  setCurrentSort,
  setPage,
} from '@/store/reducers/cars-stock';
import { handleScrollToDomElement } from '@/utils/scroll';
import {
  StockFilterMultiSelectPartsOptionType,
  StockFilterMultiSelectPartsSelectedOptionType,
} from '@/components/StockFilterMultiSelectParts/types';
import { SuperPriceFilter as SuperPriceFilterDesktop } from '@/modules/CarsStock/components/Desktop/components/Filters/components/ParamsFilter/components/SpecialFilter/components/SuperPriceFilter';
import { GuaranteeFilter as GuaranteeFilterDesktop } from '@/modules/CarsStock/components/Desktop/components/Filters/components/ParamsFilter/components/SpecialFilter/components/GuaranteeFilter';
import { ApprovedFilter as ApprovedFilterDesktop } from '@/modules/CarsStock/components/Desktop/components/Filters/components/ParamsFilter/components/SpecialFilter/components/ApprovedFilter';
import { PartnerCarFilter as PartnerCarFilterDesktop } from '@/modules/CarsStock/components/Desktop/components/Filters/components/ParamsFilter/components/SpecialFilter/components/PartnerCarFilter';
import { AvailableFilter as AvailableFilterDesktop } from '@/modules/CarsStock/components/Desktop/components/Filters/components/ParamsFilter/components/SpecialFilter/components/AvailableFilter';
import { OnStockFilter as OnStockFilterDesktop } from '@/modules/CarsStock/components/Desktop/components/Filters/components/ParamsFilter/components/SpecialFilter/components/OnStockFilter';
import { SuperPriceFilter as SuperPriceFilterMobile } from '@/modules/CarsStock/components/Mobile/components/Filters/components/ParamsFilter/components/SpecialFilter/components/SuperPriceFilter';
import { GuaranteeFilter as GuaranteeFilterMobile } from '@/modules/CarsStock/components/Mobile/components/Filters/components/ParamsFilter/components/SpecialFilter/components/GuaranteeFilter';
import { ApprovedFilter as ApprovedFilterMobile } from '@/modules/CarsStock/components/Mobile/components/Filters/components/ParamsFilter/components/SpecialFilter/components/ApprovedFilter';
import { PartnerCarFilter as PartnerCarFilterMobile } from '@/modules/CarsStock/components/Mobile/components/Filters/components/ParamsFilter/components/SpecialFilter/components/PartnerCarFilter';
import { AvailableFilter as AvailableFilterMobile } from '@/modules/CarsStock/components/Mobile/components/Filters/components/ParamsFilter/components/SpecialFilter/components/AvailableFilter';
import { OnStockFilter as OnStockFilterMobile } from '@/modules/CarsStock/components/Mobile/components/Filters/components/ParamsFilter/components/SpecialFilter/components/OnStockFilter';
import Hatchback from '@/assets/svg/car/body/hatchback.svg';
import Sedan from '@/assets/svg/car/body/sedan.svg';
import Wagon from '@/assets/svg/car/body/wagon.svg';
import Coupe from '@/assets/svg/car/body/coupe.svg';
import Minivan from '@/assets/svg/car/body/minivan.svg';
import Van from '@/assets/svg/car/body/van.svg';
import Pickup from '@/assets/svg/car/body/pickup.svg';
import Suv from '@/assets/svg/car/body/suv.svg';
import Crossover from '@/assets/svg/car/body/crossover.svg';
import Minibus from '@/assets/svg/car/body/minibus.svg';
import Liftback from '@/assets/svg/car/body/liftback.svg';
import Chassis from '@/assets/svg/car/body/chassis.svg';
import Cabriolet from '@/assets/svg/car/body/cabriolet.svg';
import ShootingBrake from '@/assets/svg/car/body/shooting-brake.svg';
import { ColorOptionType } from '@/components/StockColorPicker/types';
import defaultStockCar from '@/assets/images/cars-stock/defaultStockCar.png';
import { SEOTemplatesTypes } from '@/typings/model';
import { CarTag } from '@/components/CarTags/CarTags.types';
import { colors } from '@/constants/theme';
import { CarPriceProps } from '@/components/CarPrice/CarPrice.types';
import { getCarsStockPriceText } from '@/utils/common';
import {
  CarsStockAvailableStatus,
  CarsStockBattery,
  CarsStockBody,
  CarsStockFlag,
  CarsStockFuel,
  CarsStockGearbox,
  CarsStockGrade,
  CarsStockVat,
  CarsStockWheel,
} from '@/services/stock/enums';
import { StockFilterMultiSelectOptionType } from '@/components/StockFilterMultiSelect/types';
import CallbackForm from '@/components/modals/CallbackForm';
import { handleSubmitPartnersCarsRequestForm } from '@/utils/forms';
import { GoogleEventParamType } from '@/utils/events';
import { CarBadgesProps } from '@/components/CarBadges/types';

import {
  ComparisonCarsType,
  ConvertedFilters,
  GearboxNames,
  SelectedFilterFromUrlTypes,
} from './common.types';
import { sortingData } from './common.constants';

export const convertFilterParamsToOptionsByKey = (
  filterParams: Nullable<CarsStockFiltersParamsResponse>,
  key: keyof CarsStockFiltersParamsResponse
): OptionType[] => {
  if (!filterParams || filterParams[key].length === 0) {
    return [];
  }

  if (key === 'engineVolumes' || key === 'electricEngines') {
    return filterParams[key].map((value) => ({
      label: String(value),
      value: String(value),
    }));
  }

  return filterParams[key].map(({ id, label }) => ({
    label,
    value: String(id),
  }));
};

export const convertBrandsAndModelsToBrandsOptions = (
  brandsAndModels: CatalogStockBrandAndModelsResponse[]
): StockFilterMultiSelectOptionType[] => {
  if (!brandsAndModels || brandsAndModels.length === 0) {
    return [];
  }

  return brandsAndModels.map(({ id, name, alias }) => ({
    label: name,
    value: String(id),
    link: Router.router?.route?.replace('[[...slug]]', alias),
  }));
};

export const getModelsOptionsBySelectedBrands = (
  brandsAndModels: CatalogStockBrandAndModelsResponse[],
  selectedBrands: OptionType[]
): StockFilterMultiSelectPartsOptionType[] => {
  if (!brandsAndModels || brandsAndModels.length === 0) {
    return [];
  }

  const filteredBrandsAndModels = brandsAndModels.filter((item) =>
    selectedBrands.some((brand) => item.name === brand.label)
  );

  return filteredBrandsAndModels.map((brand) => {
    return {
      title: brand.name,
      options: brand.models.map((model) => ({
        label: model.name,
        value: String(model.id),
        link: Router.router?.route?.replace(
          '[[...slug]]',
          `${brand.alias}/${model.alias}`
        ),
      })),
    };
  });
};

export const getComplectationOptionsBySelectedModels = (
  brandsAndModels: CatalogStockBrandAndModelsResponse[],
  selectedModels: StockFilterMultiSelectPartsSelectedOptionType[]
): StockFilterMultiSelectPartsOptionType[] => {
  if (!brandsAndModels || brandsAndModels.length === 0) {
    return [];
  }

  const filteredBrandsAndModels = brandsAndModels.filter((item) =>
    item.models.some((model) =>
      selectedModels.some((selectedModel) => {
        return model.name === selectedModel.label;
      })
    )
  );

  const complectations: StockFilterMultiSelectPartsOptionType[] =
    filteredBrandsAndModels
      .map((brand) =>
        brand.models.map((model) => {
          return {
            title: model.name,
            options:
              model.complectations?.map((complectation) => ({
                label: complectation.name,
                value: String(complectation.id),
                link: Router.router?.route?.replace(
                  '[[...slug]]',
                  `${brand.alias}/${model.alias}/${complectation.alias}`
                ),
              })) ?? [],
          };
        })
      )
      .flat(1);

  return complectations.filter((complectation) =>
    selectedModels.some(
      (selectedModel) => complectation.title === selectedModel.label
    )
  );
};

export const getGearboxOptions = (
  filterParams: Nullable<CarsStockFiltersParamsResponse>
): StockFilterMultiSelectPartsOptionType[] => {
  if (!filterParams || filterParams.gearboxes.length === 0) {
    return [];
  }

  return [
    filterParams.gearboxes.filter((gear) => gear.label !== GearboxNames.MANUAL)
      .length
      ? {
          title: GearboxNames.AUTOMAT,
          options: filterParams.gearboxes
            .filter((gear) => gear.label !== GearboxNames.MANUAL)
            .map(({ id, label }) => ({ label, value: id })),
        }
      : undefined,
    filterParams.gearboxes.filter((gear) => gear.label === GearboxNames.MANUAL)
      .length
      ? { title: GearboxNames.MANUAL, options: [] }
      : undefined,
  ].filter(isNotNullish);
};

export const getColorOptions = (
  filterParams: Nullable<CarsStockFiltersParamsResponse>
): ColorOptionType[] => {
  if (!filterParams || filterParams.colors.length === 0) {
    return [];
  }

  return filterParams.colors.map(({ id, label, hex }) => {
    return {
      label,
      value: String(id),
      hex,
    };
  });
};

export const getBodyOptions = (
  filterParams: Nullable<CarsStockFiltersParamsResponse>
): StockFilterMultiSelectOptionType[] => {
  if (!filterParams || filterParams.colors.length === 0) {
    return [];
  }

  return filterParams.bodies.map(({ label, id }) => ({
    label,
    value: id,
    icon: getBodyIcon(id),
  }));
};

export const handleChangeMultiSelect = (
  option: OptionType,
  selectedOptions: OptionType[]
): OptionType[] => {
  if (
    !selectedOptions?.find(
      (searchOption) => searchOption.value === option.value
    )
  ) {
    if (selectedOptions) {
      return [...selectedOptions, option];
    } else {
      return [option];
    }
  } else {
    return selectedOptions.filter(
      (filteredOption) => filteredOption.value !== option.value
    );
  }
};

export const handleChangeColorMultiSelect = (
  option: ColorOptionType,
  selectedOptions: ColorOptionType[]
): ColorOptionType[] => {
  if (
    !selectedOptions?.find(
      (searchOption) => searchOption.value === option.value
    )
  ) {
    if (selectedOptions) {
      return [...selectedOptions, option];
    } else {
      return [option];
    }
  } else {
    return selectedOptions.filter(
      (filteredOption) => filteredOption.value !== option.value
    );
  }
};

export const handleClearRangeSelect = (
  type: RangeVariantType,
  selectedRange: RangeOptionType
): RangeOptionType => {
  if (type === 'min') {
    return {
      min: { label: '', value: '' },
      max: selectedRange.max,
    };
  }

  return {
    min: selectedRange.min,
    max: { label: '', value: '' },
  };
};

export const getCarImages = (car: CarStockItemResponse): ThumbnailType[] => {
  if (!car.image || car.images.length === 0) {
    return [{ url: defaultStockCar }];
  }

  return car.images
    .slice(0, 5)
    .map((image) => image.preview ?? { url: defaultStockCar });
};

export const getCarImageFit = (
  car: CarStockItemResponse
): 'contain' | 'cover' => {
  return car.type === 'new' || !car.image || car.images.length === 0
    ? 'contain'
    : 'cover';
};

export const getCarLink = (carType: CarsType, carId: number): string => {
  return `/cars/${carType}/${carId}`;
};

export const getCarPrice = (
  car: CarStockItemResponse,
  phone?: string
): CarPriceProps => {
  return car.priceHidden || !car.price
    ? {
        text: getCarsStockPriceText(phone, car.isPartnerCar),
      }
    : {
        amount: car.discountPrice ? car.discountPrice : car.price,
        discount:
          car.discountPrice && car.price && car.price - car.discountPrice,
        credit: {
          value: car.credit,
          link: `/cars/${car.type}/${car.id}?calculatorOpened=true`,
        },
      };
};

export const getCarBadges = (car: CarStockItemResponse): CarBadgesProps => {
  return {
    location:
      car.type === 'new'
        ? car.dealers && car.dealers.length !== 0
          ? car.dealers.map(({ address, phone }) => ({
              address,
              phone,
            }))
          : undefined
        : car.location
        ? [{ address: car.location.address, phone: car.location.phone }]
        : undefined,
    novelty: car.isNew,
    testDrive: car.availableTestDrive,
    vat: car.hasVat,
    version:
      car.version && car.versionHint
        ? { name: car.version, hint: car.versionHint }
        : undefined,
  };
};

export const getCarTags = (car: CarStockItemResponse): CarTag[] => {
  return [
    car.engineCapacity ? { title: car.engineCapacity.toFixed(1) } : undefined,
    car.fuel
      ? {
          title: switchNamesItems(car.fuel),
          background:
            car.fuel === CarsStockFuel.Electric ? colors.green200 : undefined,
        }
      : undefined,
    car.year ? { title: String(car.year) } : undefined,
    car.gearbox ? { title: switchNamesItems(car.gearbox) } : undefined,
    car.body ? { title: switchNamesItems(car.body) } : undefined,
    car.mileage
      ? { title: formatNumber(car.mileage) + ' км', infoText: 'Пробег' }
      : undefined,
    car.battery
      ? {
          title: switchNamesItems(car.battery),
        }
      : undefined,
    car.batteryCapacity
      ? {
          title: car.batteryCapacity + ' кВт/ч',
          infoText: 'Емкость батареи',
        }
      : undefined,
    car.distanceLimit
      ? {
          title: formatNumber(car.distanceLimit) + ' км',
          infoText: 'Запас хода',
        }
      : undefined,
  ].filter(isNotNullish);
};

export const getCarStatus = (car: CarStockItemResponse): string => {
  return car.status === CarsStockAvailableStatus.Booked ? 'Забронирован' : '';
};

export const handleOpenApplicationModal = (
  car: CarStockItemResponse,
  openModal: OpenModalType,
  event?: string
) => {
  const googleEventParams: GoogleEventParamType = {
    car: `${car.name} ${car.complectation ?? ''}`,
    stockStatus: car.stockStatus ? switchNamesItems(car.stockStatus) : '',
  };

  if (!car.isPartnerCar) {
    openModal(
      DetailedCarModal,
      {
        type: 'APPLICATION',
        typeState: car.type,
        car: {
          id: car.id,
          name: car.name,
          price: !car.priceHidden
            ? car.discountPrice ?? car.price ?? undefined
            : undefined,
          bodyType: car.body ? switchNamesItems(car.body) : '',
          image: car.image?.preview ?? { url: defaultStockCar },
          dealers:
            car.type === 'new' && car.dealers && car.dealers.length !== 0
              ? car.dealers.map((dealer) => ({
                  label: `${dealer.address}, ${dealer.name} `,
                  value: String(dealer.id),
                }))
              : car.location
              ? [
                  {
                    label: car.location.address,
                    value: String(car.location.id),
                  },
                ]
              : [],
          googleEventParams,
        },
      },
      { scrollLockDisabled: true, components: { Overlay: StyledOverlay } }
    );
  } else {
    openModal(
      CallbackForm,
      {
        title: 'Получить предложение',
        request: (values) =>
          handleSubmitPartnersCarsRequestForm(values, {
            type: event,
            params: googleEventParams,
          }),
        fieldsConfig: { withMessage: true },
      },
      { components: { Overlay: StyledOverlay }, scrollLockDisabled: true }
    );
  }
};

export function getFormattedResultText(resultCount: number) {
  switch (resultCount) {
    case 1:
      return 'результат';
    case 2:
    case 3:
    case 4:
      return 'результата';
    default:
      return 'результатов';
  }
}

export const handleScrollToCarsListHeader = () => {
  handleScrollToDomElement('carsListHeader', undefined, 'auto');
};

export const convertSelectedFilterObjectToArray = (
  selectedFilter: SelectedFiltersType,
  brandsAndModels: CatalogStockBrandAndModelsResponse[]
): ((OptionType | StockFilterMultiSelectPartsSelectedOptionType) & {
  name?: string;
})[] => {
  return [
    ...(brandsAndModels.length > 1
      ? selectedFilter.selectedCars.selectedBrands.map((brand) => ({
          ...brand,
          name: 'brand',
        }))
      : []),
    ...selectedFilter.selectedCars.selectedModels.map((model) => ({
      ...model,
      name: 'model',
    })),
    ...selectedFilter.selectedCars.selectedComplectations.map(
      (complectation) => ({ ...complectation, name: 'complectation' })
    ),
    ...selectedFilter.selectedParams.selectedBodies.map((body) => ({
      ...body,
      name: 'body',
    })),
    ...selectedFilter.selectedParams.selectedGearbox.map((gearbox) => ({
      ...gearbox,
      name: 'gearbox',
    })),
    selectedFilter.selectedParams.selectedPriceRange.min
      ? {
          label: `Цена от: ${selectedFilter.selectedParams.selectedPriceRange.min} BYN`,
          value: selectedFilter.selectedParams.selectedPriceRange.min,
          name: 'minPrice',
        }
      : undefined,
    selectedFilter.selectedParams.selectedPriceRange.max
      ? {
          label: `Цена до: ${selectedFilter.selectedParams.selectedPriceRange.max} BYN`,
          value: selectedFilter.selectedParams.selectedPriceRange.max,
          name: 'maxPrice',
        }
      : undefined,
    ...selectedFilter.selectedParams.selectedWheel.map((wheel) => ({
      ...wheel,
      name: 'wheel',
    })),
    ...selectedFilter.selectedParams.selectedColors.map((color) => ({
      ...color,
      name: 'color',
    })),
    ...selectedFilter.selectedParams.selectedEngineType.map((engineType) => ({
      ...engineType,
      name: 'engineType',
    })),
    ...selectedFilter.selectedParams.selectedBattery.map((battery) => ({
      ...battery,
      name: 'battery',
    })),
    selectedFilter.selectedParams.selectedEngineCapacity.min.value
      ? {
          label: `Объём двигателя от: ${selectedFilter.selectedParams.selectedEngineCapacity.min.value} л`,
          value: selectedFilter.selectedParams.selectedEngineCapacity.min.value,
          name: 'minEngineCapacity',
        }
      : undefined,
    selectedFilter.selectedParams.selectedEngineCapacity.max.value
      ? {
          label: `Объём двигателя до: ${selectedFilter.selectedParams.selectedEngineCapacity.max.value} л`,
          value: selectedFilter.selectedParams.selectedEngineCapacity.max.value,
          name: 'maxEngineCapacity',
        }
      : undefined,
    selectedFilter.selectedParams.selectedYear.min
      ? {
          label: `Год выпуска от: ${selectedFilter.selectedParams.selectedYear.min}`,
          value: selectedFilter.selectedParams.selectedYear.min,
          name: 'minYear',
        }
      : undefined,
    selectedFilter.selectedParams.selectedYear.max
      ? {
          label: `Год выпуска до: ${selectedFilter.selectedParams.selectedYear.max}`,
          value: selectedFilter.selectedParams.selectedYear.max,
          name: 'maxYear',
        }
      : undefined,
    selectedFilter.selectedParams.selectedMileage.min
      ? {
          label: `Пробег от: ${selectedFilter.selectedParams.selectedMileage.min} км`,
          value: selectedFilter.selectedParams.selectedMileage.min,
          name: 'minMileage',
        }
      : undefined,
    selectedFilter.selectedParams.selectedMileage.max
      ? {
          label: `Пробег до: ${selectedFilter.selectedParams.selectedMileage.max} км`,
          value: selectedFilter.selectedParams.selectedMileage.max,
          name: 'maxMileage',
        }
      : undefined,
    selectedFilter.selectedParams.selectedPowerReserve.min
      ? {
          label: `Запас хода от: ${selectedFilter.selectedParams.selectedPowerReserve.min} км`,
          value: selectedFilter.selectedParams.selectedPowerReserve.min,
          name: 'minPowerReserve',
        }
      : undefined,
    selectedFilter.selectedParams.selectedPowerReserve.max
      ? {
          label: `Запас хода до: ${selectedFilter.selectedParams.selectedPowerReserve.max} км`,
          value: selectedFilter.selectedParams.selectedPowerReserve.max,
          name: 'maxPowerReserve',
        }
      : undefined,
    selectedFilter.selectedParams.selectedBatteryCapacity.min
      ? {
          label: `Емкость батареи от: ${selectedFilter.selectedParams.selectedBatteryCapacity.min} кВт/ч`,
          value: selectedFilter.selectedParams.selectedBatteryCapacity.min,
          name: 'minBatteryCapacity',
        }
      : undefined,
    selectedFilter.selectedParams.selectedBatteryCapacity.max
      ? {
          label: `Емкость батареи до: ${selectedFilter.selectedParams.selectedBatteryCapacity.max} кВт/ч`,
          value: selectedFilter.selectedParams.selectedBatteryCapacity.max,
          name: 'maxBatteryCapacity',
        }
      : undefined,
    ...selectedFilter.selectedParams.selectedLocation.map((location) => ({
      ...location,
      name: 'location',
    })),
    selectedFilter.selectedParams.selectedVat.value
      ? { ...selectedFilter.selectedParams.selectedVat, name: 'vat' }
      : undefined,
    selectedFilter.selectedParams.selectedEnginePower.min
      ? {
          label: `Мощность двигателя от: ${selectedFilter.selectedParams.selectedEnginePower.min} л.c`,
          value: selectedFilter.selectedParams.selectedEnginePower.min,
          name: 'minEnginePower',
        }
      : undefined,
    selectedFilter.selectedParams.selectedEnginePower.max
      ? {
          label: `Мощность двигателя до: ${selectedFilter.selectedParams.selectedEnginePower.max} л.c`,
          value: selectedFilter.selectedParams.selectedEnginePower.max,
          name: 'maxEnginePower',
        }
      : undefined,
    selectedFilter.selectedParams.selectedClearance.min
      ? {
          label: `Клиренс от: ${selectedFilter.selectedParams.selectedClearance.min} мм`,
          value: selectedFilter.selectedParams.selectedClearance.min,
          name: 'minClearance',
        }
      : undefined,
    selectedFilter.selectedParams.selectedClearance.max
      ? {
          label: `Клиренс до: ${selectedFilter.selectedParams.selectedClearance.max} мм`,
          value: selectedFilter.selectedParams.selectedClearance.max,
          name: 'maxClearance',
        }
      : undefined,
    selectedFilter.selectedParams.selectedSeats.min
      ? {
          label: `Количество мест от: ${selectedFilter.selectedParams.selectedSeats.min}`,
          value: selectedFilter.selectedParams.selectedSeats.min,
          name: 'minSeats',
        }
      : undefined,
    selectedFilter.selectedParams.selectedSeats.max
      ? {
          label: `Количество мест до: ${selectedFilter.selectedParams.selectedSeats.max}`,
          value: selectedFilter.selectedParams.selectedSeats.max,
          name: 'maxSeats',
        }
      : undefined,
    selectedFilter.selectedParams.selectedDoors.min
      ? {
          label: `Количество дверей от: ${selectedFilter.selectedParams.selectedDoors.min}`,
          value: selectedFilter.selectedParams.selectedDoors.min,
          name: 'minDoors',
        }
      : undefined,
    selectedFilter.selectedParams.selectedDoors.max
      ? {
          label: `Количество дверей до: ${selectedFilter.selectedParams.selectedDoors.max}`,
          value: selectedFilter.selectedParams.selectedDoors.max,
          name: 'maxDoors',
        }
      : undefined,
    ...selectedFilter.selectedParams.selectedElectricEngines.map(
      (electricEngine) => ({
        ...electricEngine,
        label: `Количество электродвигателей: ${electricEngine.value}`,
        name: 'electricEngines',
      })
    ),
    ...selectedFilter.selectedParams.selectedGrades.map((grade) => ({
      ...grade,
      name: 'grade',
    })),
    selectedFilter.selectedParams.selectedSpecials.selectedSuperPrice.value
      ? {
          ...selectedFilter.selectedParams.selectedSpecials.selectedSuperPrice,
          name: 'superPrice',
        }
      : undefined,
    selectedFilter.selectedParams.selectedSpecials.selectedApproved.value
      ? {
          ...selectedFilter.selectedParams.selectedSpecials.selectedApproved,
          name: 'approved',
        }
      : undefined,
    selectedFilter.selectedParams.selectedSpecials.selectedGuarantee.value
      ? {
          ...selectedFilter.selectedParams.selectedSpecials.selectedGuarantee,
          name: 'guarantee',
        }
      : undefined,
    selectedFilter.selectedParams.selectedSpecials.selectedPartnerCar.value
      ? {
          ...selectedFilter.selectedParams.selectedSpecials.selectedPartnerCar,
          name: 'partnerCar',
        }
      : undefined,
    selectedFilter.selectedParams.selectedSpecials.selectedAvailable.value
      ? {
          ...selectedFilter.selectedParams.selectedSpecials.selectedAvailable,
          name: 'available',
        }
      : undefined,
    selectedFilter.selectedParams.selectedSpecials.selectedOnStock.value
      ? {
          ...selectedFilter.selectedParams.selectedSpecials.selectedOnStock,
          name: 'onStock',
        }
      : undefined,
  ].filter(isNotNullish);
};

export const handleDeleteSelectedFilterItem = (
  dispatch: StoreDispatch,
  selectedItem: (OptionType | StockFilterMultiSelectPartsSelectedOptionType) & {
    name?: string;
  },
  selectedFilters: SelectedFiltersType
) => {
  if (selectedItem.name === 'brand') {
    dispatch(setCarBrand(selectedItem));
  }

  if (selectedItem.name === 'model') {
    dispatch(
      setCarModel(selectedItem as StockFilterMultiSelectPartsSelectedOptionType)
    );
  }

  if (selectedItem.name === 'complectation') {
    dispatch(
      setCarComplectation(
        selectedItem as StockFilterMultiSelectPartsSelectedOptionType
      )
    );
  }

  if (selectedItem.name === 'body') {
    dispatch(
      setCarBodies(
        selectedFilters.selectedParams.selectedBodies.filter(
          (wheel) => wheel.value !== selectedItem.value
        )
      )
    );
  }

  if (selectedItem.name === 'gearbox') {
    dispatch(
      setCarGearbox(
        selectedItem as StockFilterMultiSelectPartsSelectedOptionType
      )
    );
  }

  if (selectedItem.name === 'minPrice') {
    dispatch(
      setCarPrice({
        min: '',
        max: selectedFilters.selectedParams.selectedPriceRange.max,
      })
    );
  }

  if (selectedItem.name === 'maxPrice') {
    dispatch(
      setCarPrice({
        min: selectedFilters.selectedParams.selectedPriceRange.min,
        max: '',
      })
    );
  }

  if (selectedItem.name === 'wheel') {
    dispatch(
      setCarWheel(
        selectedFilters.selectedParams.selectedWheel.filter(
          (wheel) => wheel.value !== selectedItem.value
        )
      )
    );
  }

  if (selectedItem.name === 'color') {
    dispatch(
      setCarColors(
        selectedFilters.selectedParams.selectedColors.filter(
          (color) => color.value !== selectedItem.value
        )
      )
    );
  }

  if (selectedItem.name === 'engineType') {
    dispatch(
      setCarEngineTypes(
        selectedFilters.selectedParams.selectedEngineType.filter(
          (engineType) => engineType.value !== selectedItem.value
        )
      )
    );
  }

  if (selectedItem.name === 'minEngineCapacity') {
    dispatch(
      setCarEngineCapacity({
        min: { value: '', label: '' },
        max: selectedFilters.selectedParams.selectedEngineCapacity.max,
      })
    );
  }

  if (selectedItem.name === 'maxEngineCapacity') {
    dispatch(
      setCarEngineCapacity({
        min: selectedFilters.selectedParams.selectedEngineCapacity.min,
        max: { value: '', label: '' },
      })
    );
  }

  if (selectedItem.name === 'minYear') {
    dispatch(
      setCarYear({
        min: '',
        max: selectedFilters.selectedParams.selectedYear.max,
      })
    );
  }

  if (selectedItem.name === 'maxYear') {
    dispatch(
      setCarYear({
        min: selectedFilters.selectedParams.selectedYear.min,
        max: '',
      })
    );
  }

  if (selectedItem.name === 'minMileage') {
    dispatch(
      setCarMileage({
        min: '',
        max: selectedFilters.selectedParams.selectedMileage.max,
      })
    );
  }

  if (selectedItem.name === 'maxMileage') {
    dispatch(
      setCarMileage({
        min: selectedFilters.selectedParams.selectedMileage.min,
        max: '',
      })
    );
  }

  if (selectedItem.name === 'location') {
    dispatch(
      setCarLocations(
        selectedFilters.selectedParams.selectedLocation.filter(
          (location) => location.value !== selectedItem.value
        )
      )
    );
  }

  if (selectedItem.name === 'vat') {
    dispatch(setCarVat({ value: '', label: '' }));
  }

  if (selectedItem.name === 'battery') {
    dispatch(
      setCarBattery(
        selectedFilters.selectedParams.selectedBattery.filter(
          (battery) => battery.value !== selectedItem.value
        )
      )
    );
  }

  if (selectedItem.name === 'minPowerReserve') {
    dispatch(
      setCarPowerReserve({
        min: '',
        max: selectedFilters.selectedParams.selectedPowerReserve.max,
      })
    );
  }

  if (selectedItem.name === 'maxPowerReserve') {
    dispatch(
      setCarPowerReserve({
        min: selectedFilters.selectedParams.selectedPowerReserve.min,
        max: '',
      })
    );
  }

  if (selectedItem.name === 'minBatteryCapacity') {
    dispatch(
      setCarBatteryCapacity({
        min: '',
        max: selectedFilters.selectedParams.selectedBatteryCapacity.max,
      })
    );
  }

  if (selectedItem.name === 'maxBatteryCapacity') {
    dispatch(
      setCarBatteryCapacity({
        min: selectedFilters.selectedParams.selectedBatteryCapacity.min,
        max: '',
      })
    );
  }

  if (selectedItem.name === 'minEnginePower') {
    dispatch(
      setCarEnginePower({
        min: '',
        max: selectedFilters.selectedParams.selectedEnginePower.max,
      })
    );
  }

  if (selectedItem.name === 'maxEnginePower') {
    dispatch(
      setCarEnginePower({
        min: selectedFilters.selectedParams.selectedEnginePower.min,
        max: '',
      })
    );
  }

  if (selectedItem.name === 'minClearance') {
    dispatch(
      setCarClearance({
        min: '',
        max: selectedFilters.selectedParams.selectedClearance.max,
      })
    );
  }

  if (selectedItem.name === 'maxClearance') {
    dispatch(
      setCarClearance({
        min: selectedFilters.selectedParams.selectedClearance.min,
        max: '',
      })
    );
  }

  if (selectedItem.name === 'minSeats') {
    dispatch(
      setCarSeats({
        min: '',
        max: selectedFilters.selectedParams.selectedSeats.max,
      })
    );
  }

  if (selectedItem.name === 'maxSeats') {
    dispatch(
      setCarSeats({
        min: selectedFilters.selectedParams.selectedSeats.min,
        max: '',
      })
    );
  }

  if (selectedItem.name === 'minDoors') {
    dispatch(
      setCarDoors({
        min: '',
        max: selectedFilters.selectedParams.selectedDoors.max,
      })
    );
  }

  if (selectedItem.name === 'maxDoors') {
    dispatch(
      setCarDoors({
        min: selectedFilters.selectedParams.selectedDoors.min,
        max: '',
      })
    );
  }

  if (selectedItem.name === 'electricEngines') {
    dispatch(
      setCarElectricEngines(
        selectedFilters.selectedParams.selectedElectricEngines.filter(
          (electricEngine) => electricEngine.value !== selectedItem.value
        )
      )
    );
  }

  if (selectedItem.name === 'grade') {
    dispatch(
      setCarGrades(
        selectedFilters.selectedParams.selectedGrades.filter(
          (grade) => grade.value !== selectedItem.value
        )
      )
    );
  }

  if (selectedItem.name === 'superPrice') {
    dispatch(setCarSuperPrice({ value: '', label: '' }));
  }

  if (selectedItem.name === 'guarantee') {
    dispatch(setCarGuarantee({ value: '', label: '' }));
  }

  if (selectedItem.name === 'approved') {
    dispatch(setCarApproved({ value: '', label: '' }));
  }

  if (selectedItem.name === 'partnerCar') {
    dispatch(setCarPartnerCar({ value: '', label: '' }));
  }

  if (selectedItem.name === 'available') {
    dispatch(setCarAvailable({ value: '', label: '' }));
  }

  if (selectedItem.name === 'onStock') {
    dispatch(setCarOnStock({ value: '', label: '' }));
  }
};

export const getSelectedFiltersUrl = (
  selectedFilters: SelectedFiltersType,
  brandsAndModels: CatalogStockBrandAndModelsResponse[],
  filterParams: Nullable<CarsStockFiltersParamsResponse>,
  page: number,
  sort: { item: OptionType; data: OptionType[] }
): string => {
  const brands: { id: number; name: string; alias: string }[] = [];
  const models: { id: number; name: string; alias: string }[] = [];
  const complectations: { id: number; name: string; alias: string }[] = [];
  const gearboxes: { id: string; name: string }[] = [];
  const bodies: { id: string; name: string }[] = [];
  const wheels: { id: string; name: string }[] = [];
  const colors: { id: number; name: string; hex: string }[] = [];
  const fuelTypes: { id: string; name: string }[] = [];
  const locations: { id: number; name: string }[] = [];
  const batteries: { id: string; name: string }[] = [];
  const electricEngines: { id: number; name: number }[] = [];

  brandsAndModels.forEach((brand) => {
    brands.push({ id: brand.id, name: brand.name, alias: brand.alias });
    brand.models.forEach((model) => {
      models.push({ id: model.id, name: model.name, alias: model.alias });
      model.complectations?.forEach((complectation) => {
        complectations.push({
          id: complectation.id,
          name: complectation.name,
          alias: complectation.alias,
        });
      });
    });
  });

  filterParams?.gearboxes.forEach((gearbox) =>
    gearboxes.push({ id: gearbox.id, name: gearbox.label })
  );

  const selectedGearboxes = gearboxes.filter(
    (gearbox) =>
      selectedFilters.selectedParams.selectedGearbox.filter(
        (item) => item.value === gearbox.id
      ).length
  );

  filterParams?.bodies.forEach((body) =>
    bodies.push({ id: body.id, name: body.label })
  );

  const selectedBodies = bodies.filter(
    (body) =>
      selectedFilters.selectedParams.selectedBodies.filter(
        (item) => item.value === body.id
      ).length
  );

  filterParams?.wheels.forEach((wheel) =>
    wheels.push({ id: wheel.id, name: wheel.label })
  );

  const selectedWheels = wheels.filter(
    (wheel) =>
      selectedFilters.selectedParams.selectedWheel.filter(
        (item) => item.value === wheel.id
      ).length
  );

  filterParams?.colors.forEach((color) =>
    colors.push({ id: color.id, name: color.label, hex: color.hex })
  );

  const selectedColors = colors.filter(
    (color) =>
      selectedFilters.selectedParams.selectedColors.filter(
        (item) => item.value === String(color.id)
      ).length
  );

  filterParams?.fuelTypes.forEach((fuelType) =>
    fuelTypes.push({ id: fuelType.id, name: fuelType.label })
  );

  const selectedFuelTypes = fuelTypes.filter(
    (fuelType) =>
      selectedFilters.selectedParams.selectedEngineType.filter(
        (item) => item.value === fuelType.id
      ).length
  );

  filterParams?.locations.forEach((location) =>
    locations.push({ id: location.id, name: location.label })
  );

  const selectedLocations = locations.filter(
    (location) =>
      selectedFilters.selectedParams.selectedLocation.filter(
        (item) => item.value === String(location.id)
      ).length
  );

  filterParams?.batteryTypes.forEach((batteryType) =>
    batteries.push({ id: batteryType.id, name: batteryType.label })
  );

  const selectedBatteryTypes = batteries.filter(
    (batteryType) =>
      selectedFilters.selectedParams.selectedBattery.filter(
        (item) => item.value === batteryType.id
      ).length
  );

  const selectedBrands = brands.filter(
    (brand) =>
      selectedFilters.selectedCars.selectedBrands.filter(
        (item) => +item.value === brand.id
      ).length
  );

  const selectedModels = models.filter(
    (model) =>
      selectedFilters.selectedCars.selectedModels.filter(
        (item) => +item.value === model.id
      ).length
  );

  const selectedComplectation = complectations.filter(
    (complectation) =>
      selectedFilters.selectedCars.selectedComplectations.filter(
        (item) => +item.value === complectation.id
      ).length
  );

  filterParams?.electricEngines.forEach((electricEngine) =>
    electricEngines.push({ id: electricEngine, name: electricEngine })
  );

  const selectedElectricEngines = electricEngines.filter(
    (electricEngine) =>
      selectedFilters.selectedParams.selectedElectricEngines.filter(
        (item) => item.value === String(electricEngine.id)
      ).length
  );

  const otherSelects = [
    ...selectedGearboxes,
    ...selectedBodies,
    ...selectedWheels,
    ...selectedColors,
    ...selectedFuelTypes,
    ...selectedLocations,
    ...selectedBatteryTypes,
    ...selectedElectricEngines,
    ...selectedFilters.selectedParams.selectedGrades,
    selectedFilters.selectedParams.selectedVat.value
      ? selectedFilters.selectedParams.selectedVat.value
      : undefined,
    selectedFilters.selectedParams.selectedPriceRange.max
      ? selectedFilters.selectedParams.selectedPriceRange.max
      : undefined,
    selectedFilters.selectedParams.selectedPriceRange.min
      ? selectedFilters.selectedParams.selectedPriceRange.min
      : undefined,
    selectedFilters.selectedParams.selectedMileage.min
      ? selectedFilters.selectedParams.selectedMileage.min
      : undefined,
    selectedFilters.selectedParams.selectedMileage.max
      ? selectedFilters.selectedParams.selectedMileage.max
      : undefined,
    selectedFilters.selectedParams.selectedYear.min
      ? selectedFilters.selectedParams.selectedYear.min
      : undefined,
    selectedFilters.selectedParams.selectedYear.max
      ? selectedFilters.selectedParams.selectedYear.max
      : undefined,
    selectedFilters.selectedParams.selectedEngineCapacity.min.value
      ? selectedFilters.selectedParams.selectedEngineCapacity.min.value
      : undefined,
    selectedFilters.selectedParams.selectedEngineCapacity.max.value
      ? selectedFilters.selectedParams.selectedEngineCapacity.max.value
      : undefined,
    selectedFilters.selectedParams.selectedPowerReserve.min
      ? selectedFilters.selectedParams.selectedPowerReserve.min
      : undefined,
    selectedFilters.selectedParams.selectedPowerReserve.max
      ? selectedFilters.selectedParams.selectedPowerReserve.max
      : undefined,
    selectedFilters.selectedParams.selectedBatteryCapacity.min
      ? selectedFilters.selectedParams.selectedBatteryCapacity.min
      : undefined,
    selectedFilters.selectedParams.selectedBatteryCapacity.max
      ? selectedFilters.selectedParams.selectedBatteryCapacity.max
      : undefined,
    selectedFilters.selectedParams.selectedEnginePower.min
      ? selectedFilters.selectedParams.selectedEnginePower.min
      : undefined,
    selectedFilters.selectedParams.selectedEnginePower.max
      ? selectedFilters.selectedParams.selectedEnginePower.max
      : undefined,
    selectedFilters.selectedParams.selectedClearance.min
      ? selectedFilters.selectedParams.selectedClearance.min
      : undefined,
    selectedFilters.selectedParams.selectedClearance.max
      ? selectedFilters.selectedParams.selectedClearance.max
      : undefined,
    selectedFilters.selectedParams.selectedSeats.min
      ? selectedFilters.selectedParams.selectedSeats.min
      : undefined,
    selectedFilters.selectedParams.selectedSeats.max
      ? selectedFilters.selectedParams.selectedSeats.max
      : undefined,
    selectedFilters.selectedParams.selectedDoors.min
      ? selectedFilters.selectedParams.selectedDoors.min
      : undefined,
    selectedFilters.selectedParams.selectedDoors.max
      ? selectedFilters.selectedParams.selectedDoors.max
      : undefined,
    selectedFilters.selectedParams.selectedSpecials.selectedSuperPrice.value
      ? selectedFilters.selectedParams.selectedSpecials.selectedSuperPrice.value
      : undefined,
    selectedFilters.selectedParams.selectedSpecials.selectedGuarantee.value
      ? selectedFilters.selectedParams.selectedSpecials.selectedGuarantee.value
      : undefined,
    selectedFilters.selectedParams.selectedSpecials.selectedApproved.value
      ? selectedFilters.selectedParams.selectedSpecials.selectedApproved.value
      : undefined,
    selectedFilters.selectedParams.selectedSpecials.selectedPartnerCar.value
      ? selectedFilters.selectedParams.selectedSpecials.selectedPartnerCar.value
      : undefined,
    selectedFilters.selectedParams.selectedSpecials.selectedAvailable.value
      ? selectedFilters.selectedParams.selectedSpecials.selectedAvailable.value
      : undefined,
    selectedFilters.selectedParams.selectedSpecials.selectedOnStock.value
      ? selectedFilters.selectedParams.selectedSpecials.selectedOnStock.value
      : undefined,
  ].filter(isNotNullish);

  // Получение урла если выбрана марка, модель, комплектация машины
  if (selectedBrands.length === 1 && !otherSelects.length) {
    const pageAndSortSlug =
      (sort.item.value !== sort.data[0].value
        ? `--sort-${sort.item.value}`
        : '') + (page !== 1 ? `--page-${page}` : '');

    if (selectedModels.length === 1) {
      if (selectedComplectation.length === 1) {
        const newUrl = `${selectedBrands[0].alias}/${selectedModels[0].alias}/${selectedComplectation[0].alias}`;
        return newUrl + pageAndSortSlug;
      } else if (!selectedComplectation.length) {
        const newUrl = `${selectedBrands[0].alias}/${selectedModels[0].alias}`;
        return newUrl + pageAndSortSlug;
      }
    } else if (!selectedModels.length) {
      const newUrl = `${selectedBrands[0].alias}`;
      return newUrl + pageAndSortSlug;
    }
  }

  // Получения урла если выбрана не только марка, модель, комплектация машины
  let url = '';

  if (selectedBrands.length) {
    url += `filter-brand-${selectedBrands.map((item) => item.alias).join('-')}`;
  }

  if (selectedModels.length) {
    url += `--model-${selectedModels.map((item) => item.alias).join('-')}`;
  }

  if (selectedComplectation.length) {
    url += `--complectation-${selectedComplectation
      .map((item) => item.alias)
      .join('-')}`;
  }

  if (selectedGearboxes.length) {
    !url
      ? (url += `filter-gearbox-${selectedGearboxes
          .map((item) => item.id.toLowerCase())
          .join('-')}`)
      : (url += `--gearbox-${selectedGearboxes
          .map((item) => item.id.toLowerCase())
          .join('-')}`);
  }

  if (selectedBodies.length) {
    !url
      ? (url += `filter-body-${selectedBodies
          .map((item) => item.id.toLowerCase())
          .join('-')}`)
      : (url += `--body-${selectedBodies
          .map((item) => item.id.toLowerCase())
          .join('-')}`);
  }

  if (selectedWheels.length) {
    !url
      ? (url += `filter-wheel-${selectedWheels
          .map((item) => item.id.toLowerCase())
          .join('-')}`)
      : (url += `--wheel-${selectedWheels
          .map((item) => item.id.toLowerCase())
          .join('-')}`);
  }

  if (selectedColors.length) {
    !url
      ? (url += `filter-color-${selectedColors
          .map((item) => item.id)
          .join('-')}`)
      : (url += `--color-${selectedColors.map((item) => item.id).join('-')}`);
  }

  if (selectedFuelTypes.length) {
    !url
      ? (url += `filter-fuel_type-${selectedFuelTypes
          .map((item) => item.id.toLowerCase())
          .join('-')}`)
      : (url += `--fuel_type-${selectedFuelTypes
          .map((item) => item.id.toLowerCase())
          .join('-')}`);
  }

  if (selectedLocations.length) {
    !url
      ? (url += `filter-location-${selectedLocations
          .map((item) => item.id)
          .join('-')}`)
      : (url += `--location-${selectedLocations
          .map((item) => item.id)
          .join('-')}`);
  }

  if (selectedBatteryTypes.length) {
    !url
      ? (url += `filter-battery-${selectedBatteryTypes
          .map((item) => item.id.toLowerCase())
          .join('-')}`)
      : (url += `--battery-${selectedBatteryTypes
          .map((item) => item.id.toLowerCase())
          .join('-')}`);
  }

  if (selectedElectricEngines.length) {
    !url
      ? (url += `filter-electric_engines-${selectedElectricEngines
          .map((item) => item.id)
          .join('-')}`)
      : (url += `--electric_engines-${selectedElectricEngines
          .map((item) => item.id)
          .join('-')}`);
  }

  if (selectedFilters.selectedParams.selectedGrades.length) {
    !url
      ? (url += `filter-grade-${selectedFilters.selectedParams.selectedGrades
          .map((item) => item.value.toLowerCase())
          .join('-')}`)
      : (url += `--grade-${selectedFilters.selectedParams.selectedGrades
          .map((item) => item.value.toLowerCase())
          .join('-')}`);
  }

  if (selectedFilters.selectedParams.selectedVat.value) {
    !url
      ? (url += `filter-vat-${selectedFilters.selectedParams.selectedVat.value.toLowerCase()}`)
      : (url += `--vat-${selectedFilters.selectedParams.selectedVat.value.toLowerCase()}`);
  }

  if (selectedFilters.selectedParams.selectedPriceRange.max) {
    !url
      ? (url += `filter-max_price-${selectedFilters.selectedParams.selectedPriceRange.max}`)
      : (url += `--max_price-${selectedFilters.selectedParams.selectedPriceRange.max}`);
  }

  if (selectedFilters.selectedParams.selectedPriceRange.min) {
    !url
      ? (url += `filter-min_price-${selectedFilters.selectedParams.selectedPriceRange.min}`)
      : (url += `--min_price-${selectedFilters.selectedParams.selectedPriceRange.min}`);
  }

  if (selectedFilters.selectedParams.selectedMileage.min) {
    !url
      ? (url += `filter-mileage-${selectedFilters.selectedParams.selectedMileage.min}`)
      : (url += `--mileage-${selectedFilters.selectedParams.selectedMileage.min}`);
  }

  if (selectedFilters.selectedParams.selectedMileage.min) {
    !url
      ? (url += `filter-min_mileage-${selectedFilters.selectedParams.selectedMileage.min}`)
      : (url += `--min_mileage-${selectedFilters.selectedParams.selectedMileage.min}`);
  }

  if (selectedFilters.selectedParams.selectedMileage.max) {
    !url
      ? (url += `filter-max_mileage-${selectedFilters.selectedParams.selectedMileage.max}`)
      : (url += `--max_mileage-${selectedFilters.selectedParams.selectedMileage.max}`);
  }

  if (selectedFilters.selectedParams.selectedYear.min) {
    !url
      ? (url += `filter-min_year-${selectedFilters.selectedParams.selectedYear.min}`)
      : (url += `--min_year-${selectedFilters.selectedParams.selectedYear.min}`);
  }

  if (selectedFilters.selectedParams.selectedYear.max) {
    !url
      ? (url += `filter-max_year-${selectedFilters.selectedParams.selectedYear.max}`)
      : (url += `--max_year-${selectedFilters.selectedParams.selectedYear.max}`);
  }

  if (selectedFilters.selectedParams.selectedEngineCapacity.min.value) {
    !url
      ? (url += `filter-min_engine_volume-${selectedFilters.selectedParams.selectedEngineCapacity.min.value}`)
      : (url += `--min_engine_volume-${selectedFilters.selectedParams.selectedEngineCapacity.min.value}`);
  }

  if (selectedFilters.selectedParams.selectedEngineCapacity.max.value) {
    !url
      ? (url += `filter-max_engine_volume-${selectedFilters.selectedParams.selectedEngineCapacity.max.value}`)
      : (url += `--max_engine_volume-${selectedFilters.selectedParams.selectedEngineCapacity.max.value}`);
  }

  if (selectedFilters.selectedParams.selectedPowerReserve.min) {
    !url
      ? (url += `filter-min_power_reserve-${selectedFilters.selectedParams.selectedPowerReserve.min}`)
      : (url += `--min_power_reserve-${selectedFilters.selectedParams.selectedPowerReserve.min}`);
  }

  if (selectedFilters.selectedParams.selectedPowerReserve.max) {
    !url
      ? (url += `filter-max_power_reserve-${selectedFilters.selectedParams.selectedPowerReserve.max}`)
      : (url += `--max_power_reserve-${selectedFilters.selectedParams.selectedPowerReserve.max}`);
  }

  if (selectedFilters.selectedParams.selectedBatteryCapacity.min) {
    !url
      ? (url += `filter-min_battery_capacity-${selectedFilters.selectedParams.selectedBatteryCapacity.min}`)
      : (url += `--min_battery_capacity-${selectedFilters.selectedParams.selectedBatteryCapacity.min}`);
  }

  if (selectedFilters.selectedParams.selectedBatteryCapacity.max) {
    !url
      ? (url += `filter-max_battery_capacity-${selectedFilters.selectedParams.selectedBatteryCapacity.max}`)
      : (url += `--max_battery_capacity-${selectedFilters.selectedParams.selectedBatteryCapacity.max}`);
  }

  if (selectedFilters.selectedParams.selectedEnginePower.min) {
    !url
      ? (url += `filter-min_engine_power-${selectedFilters.selectedParams.selectedEnginePower.min}`)
      : (url += `--min_engine_power-${selectedFilters.selectedParams.selectedEnginePower.min}`);
  }

  if (selectedFilters.selectedParams.selectedEnginePower.max) {
    !url
      ? (url += `filter-max_engine_power-${selectedFilters.selectedParams.selectedEnginePower.max}`)
      : (url += `--max_engine_power-${selectedFilters.selectedParams.selectedEnginePower.max}`);
  }

  if (selectedFilters.selectedParams.selectedClearance.min) {
    !url
      ? (url += `filter-min_clearance-${selectedFilters.selectedParams.selectedClearance.min}`)
      : (url += `--min_clearance-${selectedFilters.selectedParams.selectedClearance.min}`);
  }

  if (selectedFilters.selectedParams.selectedClearance.max) {
    !url
      ? (url += `filter-max_clearance-${selectedFilters.selectedParams.selectedClearance.max}`)
      : (url += `--max_clearance-${selectedFilters.selectedParams.selectedClearance.max}`);
  }

  if (selectedFilters.selectedParams.selectedSeats.min) {
    !url
      ? (url += `filter-min_seats-${selectedFilters.selectedParams.selectedSeats.min}`)
      : (url += `--min_seats-${selectedFilters.selectedParams.selectedSeats.min}`);
  }

  if (selectedFilters.selectedParams.selectedSeats.max) {
    !url
      ? (url += `filter-max_seats-${selectedFilters.selectedParams.selectedSeats.max}`)
      : (url += `--max_seats-${selectedFilters.selectedParams.selectedSeats.max}`);
  }

  if (selectedFilters.selectedParams.selectedDoors.min) {
    !url
      ? (url += `filter-min_doors-${selectedFilters.selectedParams.selectedDoors.min}`)
      : (url += `--min_doors-${selectedFilters.selectedParams.selectedDoors.min}`);
  }

  if (selectedFilters.selectedParams.selectedDoors.max) {
    !url
      ? (url += `filter-max_doors-${selectedFilters.selectedParams.selectedDoors.max}`)
      : (url += `--max_doors-${selectedFilters.selectedParams.selectedDoors.max}`);
  }

  if (selectedFilters.selectedParams.selectedSpecials.selectedApproved.value) {
    !url
      ? (url += `filter-${selectedFilters.selectedParams.selectedSpecials.selectedApproved.value.toLowerCase()}`)
      : (url += `--${selectedFilters.selectedParams.selectedSpecials.selectedApproved.value.toLowerCase()}`);
  }

  if (selectedFilters.selectedParams.selectedSpecials.selectedGuarantee.value) {
    !url
      ? (url += `filter-${selectedFilters.selectedParams.selectedSpecials.selectedGuarantee.value.toLowerCase()}`)
      : (url += `--${selectedFilters.selectedParams.selectedSpecials.selectedGuarantee.value.toLowerCase()}`);
  }

  if (
    selectedFilters.selectedParams.selectedSpecials.selectedSuperPrice.value
  ) {
    !url
      ? (url += `filter-${selectedFilters.selectedParams.selectedSpecials.selectedSuperPrice.value.toLowerCase()}`)
      : (url += `--${selectedFilters.selectedParams.selectedSpecials.selectedSuperPrice.value.toLowerCase()}`);
  }

  if (
    selectedFilters.selectedParams.selectedSpecials.selectedPartnerCar.value
  ) {
    !url
      ? (url += `filter-${selectedFilters.selectedParams.selectedSpecials.selectedPartnerCar.value.toLowerCase()}`)
      : (url += `--${selectedFilters.selectedParams.selectedSpecials.selectedPartnerCar.value.toLowerCase()}`);
  }

  if (selectedFilters.selectedParams.selectedSpecials.selectedAvailable.value) {
    !url
      ? (url += `filter-${selectedFilters.selectedParams.selectedSpecials.selectedAvailable.value.toLowerCase()}`)
      : (url += `--${selectedFilters.selectedParams.selectedSpecials.selectedAvailable.value.toLowerCase()}`);
  }

  if (selectedFilters.selectedParams.selectedSpecials.selectedOnStock.value) {
    !url
      ? (url += `filter-${selectedFilters.selectedParams.selectedSpecials.selectedOnStock.value.toLowerCase()}`)
      : (url += `--${selectedFilters.selectedParams.selectedSpecials.selectedOnStock.value.toLowerCase()}`);
  }

  if (sort.item.value !== sort.data[0].value) {
    !url
      ? (url += `sort-${sort.item.value}`)
      : (url += `--sort-${sort.item.value}`);
  }

  if (page !== 1) {
    !url ? (url += `page-${page}`) : (url += `--page-${page}`);
  }

  return url;
};

export const createFilterPayload = (
  selectedFilters: SelectedFiltersType,
  brandsAndModels: CatalogStockBrandAndModelsResponse[]
): SelectedFiltersPayload => {
  const filterPayload: SelectedFiltersPayload = { filter: {} };

  if (selectedFilters.selectedParams.selectedBodies.length) {
    filterPayload.filter.bodies =
      selectedFilters.selectedParams.selectedBodies.map(
        (item) => item.value
      ) as CarsStockBody[];
  }

  if (selectedFilters.selectedParams.selectedGearbox.length) {
    filterPayload.filter.gearboxes =
      selectedFilters.selectedParams.selectedGearbox.map(
        (item) => item.value
      ) as CarsStockGearbox[];
  }

  if (
    selectedFilters.selectedParams.selectedPriceRange.min ||
    selectedFilters.selectedParams.selectedPriceRange.max
  ) {
    filterPayload.filter.price = {};

    if (selectedFilters.selectedParams.selectedPriceRange.min) {
      filterPayload.filter.price.min = Number(
        selectedFilters.selectedParams.selectedPriceRange.min
      );
    }

    if (selectedFilters.selectedParams.selectedPriceRange.max) {
      filterPayload.filter.price.max = Number(
        selectedFilters.selectedParams.selectedPriceRange.max
      );
    }
  }

  if (selectedFilters.selectedParams.selectedWheel.length) {
    filterPayload.filter.wheels =
      selectedFilters.selectedParams.selectedWheel.map(
        (item) => item.value
      ) as CarsStockWheel[];
  }

  if (selectedFilters.selectedParams.selectedColors.length) {
    filterPayload.filter.colors =
      selectedFilters.selectedParams.selectedColors.map((item) => item.value);
  }

  if (selectedFilters.selectedParams.selectedEngineType.length) {
    filterPayload.filter.fuelTypes =
      selectedFilters.selectedParams.selectedEngineType.map(
        (item) => item.value
      ) as CarsStockFuel[];
  }

  if (
    selectedFilters.selectedParams.selectedEngineCapacity.min.value ||
    selectedFilters.selectedParams.selectedEngineCapacity.max.value
  ) {
    filterPayload.filter.engineVolume = {};

    if (selectedFilters.selectedParams.selectedEngineCapacity.min.value) {
      filterPayload.filter.engineVolume.min = Number(
        selectedFilters.selectedParams.selectedEngineCapacity.min.value
      );
    }

    if (selectedFilters.selectedParams.selectedEngineCapacity.max.value) {
      filterPayload.filter.engineVolume.max = Number(
        selectedFilters.selectedParams.selectedEngineCapacity.max.value
      );
    }
  }

  if (
    selectedFilters.selectedParams.selectedMileage.min ||
    selectedFilters.selectedParams.selectedMileage.max
  ) {
    filterPayload.filter.mileage = {};

    if (selectedFilters.selectedParams.selectedMileage.min) {
      filterPayload.filter.mileage.min = Number(
        selectedFilters.selectedParams.selectedMileage.min
      );
    }

    if (selectedFilters.selectedParams.selectedMileage.max) {
      filterPayload.filter.mileage.max = Number(
        selectedFilters.selectedParams.selectedMileage.max
      );
    }
  }

  if (
    selectedFilters.selectedParams.selectedYear.min ||
    selectedFilters.selectedParams.selectedYear.max
  ) {
    filterPayload.filter.year = {};

    if (selectedFilters.selectedParams.selectedYear.min) {
      filterPayload.filter.year.min = Number(
        selectedFilters.selectedParams.selectedYear.min
      );
    }

    if (selectedFilters.selectedParams.selectedYear.max) {
      filterPayload.filter.year.max = Number(
        selectedFilters.selectedParams.selectedYear.max
      );
    }
  }

  if (selectedFilters.selectedParams.selectedLocation.length) {
    filterPayload.filter.locations =
      selectedFilters.selectedParams.selectedLocation.map(
        (item) => +item.value
      );
  }

  if (selectedFilters.selectedParams.selectedVat.value) {
    filterPayload.filter.vat = selectedFilters.selectedParams.selectedVat
      .value as CarsStockVat;
  }

  if (
    selectedFilters.selectedParams.selectedPowerReserve.min ||
    selectedFilters.selectedParams.selectedPowerReserve.max
  ) {
    filterPayload.filter.powerReserve = {};

    if (selectedFilters.selectedParams.selectedPowerReserve.min) {
      filterPayload.filter.powerReserve.min = Number(
        selectedFilters.selectedParams.selectedPowerReserve.min
      );
    }

    if (selectedFilters.selectedParams.selectedPowerReserve.max) {
      filterPayload.filter.powerReserve.max = Number(
        selectedFilters.selectedParams.selectedPowerReserve.max
      );
    }
  }

  if (selectedFilters.selectedParams.selectedBattery.length) {
    filterPayload.filter.batteries =
      selectedFilters.selectedParams.selectedBattery.map(
        (item) => item.value
      ) as CarsStockBattery[];
  }

  if (
    selectedFilters.selectedParams.selectedBatteryCapacity.min ||
    selectedFilters.selectedParams.selectedBatteryCapacity.max
  ) {
    filterPayload.filter.batteryCapacity = {};

    if (selectedFilters.selectedParams.selectedBatteryCapacity.min) {
      filterPayload.filter.batteryCapacity.min = Number(
        selectedFilters.selectedParams.selectedBatteryCapacity.min
      );
    }

    if (selectedFilters.selectedParams.selectedBatteryCapacity.max) {
      filterPayload.filter.batteryCapacity.max = Number(
        selectedFilters.selectedParams.selectedBatteryCapacity.max
      );
    }
  }

  if (selectedFilters.selectedParams.selectedElectricEngines.length) {
    filterPayload.filter.electricEngines =
      selectedFilters.selectedParams.selectedElectricEngines.map((item) =>
        Number(item.value)
      );
  }

  if (
    selectedFilters.selectedParams.selectedEnginePower.min ||
    selectedFilters.selectedParams.selectedEnginePower.max
  ) {
    filterPayload.filter.enginePower = {};

    if (selectedFilters.selectedParams.selectedEnginePower.min) {
      filterPayload.filter.enginePower.min = Number(
        selectedFilters.selectedParams.selectedEnginePower.min
      );
    }

    if (selectedFilters.selectedParams.selectedEnginePower.max) {
      filterPayload.filter.enginePower.max = Number(
        selectedFilters.selectedParams.selectedEnginePower.max
      );
    }
  }

  if (
    selectedFilters.selectedParams.selectedClearance.min ||
    selectedFilters.selectedParams.selectedClearance.max
  ) {
    filterPayload.filter.clearance = {};

    if (selectedFilters.selectedParams.selectedClearance.min) {
      filterPayload.filter.clearance.min = Number(
        selectedFilters.selectedParams.selectedClearance.min
      );
    }

    if (selectedFilters.selectedParams.selectedClearance.max) {
      filterPayload.filter.clearance.max = Number(
        selectedFilters.selectedParams.selectedClearance.max
      );
    }
  }

  if (
    selectedFilters.selectedParams.selectedSeats.min ||
    selectedFilters.selectedParams.selectedSeats.max
  ) {
    filterPayload.filter.seats = {};

    if (selectedFilters.selectedParams.selectedSeats.min) {
      filterPayload.filter.seats.min = Number(
        selectedFilters.selectedParams.selectedSeats.min
      );
    }

    if (selectedFilters.selectedParams.selectedSeats.max) {
      filterPayload.filter.seats.max = Number(
        selectedFilters.selectedParams.selectedSeats.max
      );
    }
  }

  if (
    selectedFilters.selectedParams.selectedDoors.min ||
    selectedFilters.selectedParams.selectedDoors.max
  ) {
    filterPayload.filter.doors = {};

    if (selectedFilters.selectedParams.selectedDoors.min) {
      filterPayload.filter.doors.min = Number(
        selectedFilters.selectedParams.selectedDoors.min
      );
    }

    if (selectedFilters.selectedParams.selectedDoors.max) {
      filterPayload.filter.doors.max = Number(
        selectedFilters.selectedParams.selectedDoors.max
      );
    }
  }

  if (selectedFilters.selectedParams.selectedGrades.length) {
    filterPayload.filter.grades =
      selectedFilters.selectedParams.selectedGrades.map(
        (item) => item.value
      ) as CarsStockGrade[];
  }

  if (selectedFilters.selectedCars.selectedBrands.length) {
    filterPayload.filter.catalog =
      selectedFilters.selectedCars.selectedBrands.map((brand) => {
        const newBrand: {
          brand: number;
          models?: { id?: number; complectations?: number[] }[];
        } = { brand: Number(brand.value) };

        const newModels = brandsAndModels
          .filter((item) => item.id === Number(brand.value))[0]
          .models?.filter(
            (modelItem) =>
              !!selectedFilters.selectedCars.selectedModels.filter(
                (selected) => Number(selected.value) === modelItem.id
              ).length
          );

        if (newModels && newModels.length) {
          newBrand.models = newModels.map((model) => {
            const newModel: { id: number; complectations?: number[] } = {
              id: model.id,
            };

            const newComplectations = brandsAndModels
              .filter((item) => item.id === Number(brand.value))[0]
              .models.filter((modelItem) => modelItem.id === model.id)[0]
              .complectations?.filter(
                (complectationsItem) =>
                  !!selectedFilters.selectedCars.selectedComplectations.filter(
                    (selected) =>
                      Number(selected.value) === complectationsItem.id
                  ).length
              );

            if (newComplectations && newComplectations.length) {
              newModel.complectations = newComplectations.map(
                (complectationsItem) => complectationsItem.id
              );
            }

            return newModel;
          });
        }

        return newBrand;
      });
  }

  if (
    selectedFilters.selectedParams.selectedSpecials.selectedApproved.value ||
    selectedFilters.selectedParams.selectedSpecials.selectedGuarantee.value ||
    selectedFilters.selectedParams.selectedSpecials.selectedSuperPrice.value ||
    selectedFilters.selectedParams.selectedSpecials.selectedPartnerCar.value ||
    selectedFilters.selectedParams.selectedSpecials.selectedAvailable.value ||
    selectedFilters.selectedParams.selectedSpecials.selectedOnStock.value
  ) {
    filterPayload.filter.flags = Object.values(
      selectedFilters.selectedParams.selectedSpecials
    )
      .map((item) => item.value)
      .filter((item) => item) as CarsStockFlag[];
  }

  return filterPayload;
};

export const setSelectedFiltersQuery = (
  selectedFilters: SelectedFiltersType,
  brandsAndModels: CatalogStockBrandAndModelsResponse[],
  filterParams: Nullable<CarsStockFiltersParamsResponse>,
  page: number,
  sort: { item: OptionType; data: OptionType[] }
) => {
  const url = getSelectedFiltersUrl(
    selectedFilters,
    brandsAndModels,
    filterParams,
    page,
    sort
  );

  Router.replace(url, undefined, { shallow: true });

  return url;
};

export const getSpecialFilterOptionById = (
  filterParams: Nullable<CarsStockFiltersParamsResponse>,
  id: CarsStockFlag
): OptionType => {
  if (!filterParams) {
    return { label: '', value: '' };
  }

  return convertFilterParamsToOptionsByKey(filterParams, 'flags')
    .filter((flag) => flag.value === id)
    .map((flag) => ({ label: flag.label, value: flag.value }))
    .reduce<OptionType>(
      (acc, currentValue) => {
        return currentValue;
      },
      { label: '', value: '' }
    );
};

export const getSpecialFilter = (id: CarsStockFlag, isMobile?: boolean) => {
  switch (id) {
    case CarsStockFlag.SuperPrice:
      if (!isMobile) {
        return <SuperPriceFilterDesktop />;
      }

      return <SuperPriceFilterMobile />;
    case CarsStockFlag.OnWarranty:
      if (!isMobile) {
        return <GuaranteeFilterDesktop />;
      }

      return <GuaranteeFilterMobile />;
    case CarsStockFlag.Approved:
      if (!isMobile) {
        return <ApprovedFilterDesktop />;
      }

      return <ApprovedFilterMobile />;
    case CarsStockFlag.PartnerCar:
      if (!isMobile) {
        return <PartnerCarFilterDesktop />;
      }

      return <PartnerCarFilterMobile />;
    case CarsStockFlag.Available:
      if (!isMobile) {
        return <AvailableFilterDesktop />;
      }

      return <AvailableFilterMobile />;
    case CarsStockFlag.OnStock:
      if (!isMobile) {
        return <OnStockFilterDesktop />;
      }

      return <OnStockFilterMobile />;
    default:
      return <></>;
  }
};

export const getSelectedFilterFromUrl = (
  slug?: string | string[]
): SelectedFilterFromUrlTypes => {
  const selectedFilters: SelectedFilterFromUrlTypes = {};

  if (Array.isArray(slug)) {
    if (slug.length === 1 && slug[0].substring(0, 7) === 'filter-') {
      const newArray = slug[0]
        .substring(7)
        .split('--')
        .map((item) => item.split('-'));

      newArray.forEach((item) => {
        if (item[0] === 'brand') {
          selectedFilters.brand = [...item.slice(1)];
        }

        if (item[0] === 'model') {
          selectedFilters.model = [...item.slice(1)];
        }

        if (item[0] === 'complectation') {
          selectedFilters.complectation = [...item.slice(1)];
        }

        if (item[0] === 'gearbox') {
          selectedFilters.gearboxes = [...item.slice(1)];
        }

        if (item[0] === 'body') {
          selectedFilters.bodies = [...item.slice(1)];
        }

        if (item[0] === 'wheel') {
          selectedFilters.wheels = [...item.slice(1)];
        }

        if (item[0] === 'color') {
          selectedFilters.colors = [...item.slice(1)];
        }

        if (item[0] === 'fuel_type') {
          selectedFilters.fuelTypes = [...item.slice(1)];
        }

        if (item[0] === 'location') {
          selectedFilters.locations = [...item.slice(1)];
        }

        if (item[0] === 'battery') {
          selectedFilters.batteries = [...item.slice(1)];
        }

        if (item[0] === 'electric_engines') {
          selectedFilters.electricEngines = [...item.slice(1)];
        }

        if (item[0] === 'grade') {
          selectedFilters.grades = [...item.slice(1)];
        }

        if (item[0] === 'vat') {
          selectedFilters.vat = item[1];
        }

        if (item[0] === 'min_price') {
          selectedFilters.minPrice = item[1];
        }

        if (item[0] === 'max_price') {
          selectedFilters.maxPrice = item[1];
        }

        if (item[0] === 'min_mileage') {
          selectedFilters.minMileage = item[1];
        }

        if (item[0] === 'max_mileage') {
          selectedFilters.maxMileage = item[1];
        }

        if (item[0] === 'min_year') {
          selectedFilters.minYear = item[1];
        }

        if (item[0] === 'max_year') {
          selectedFilters.maxYear = item[1];
        }

        if (item[0] === 'min_engine_volume') {
          selectedFilters.minEngineVolume = item[1];
        }

        if (item[0] === 'max_engine_volume') {
          selectedFilters.maxEngineVolume = item[1];
        }

        if (item[0] === 'min_power_reserve') {
          selectedFilters.minPowerReserve = item[1];
        }

        if (item[0] === 'max_power_reserve') {
          selectedFilters.maxPowerReserve = item[1];
        }

        if (item[0] === 'min_battery_capacity') {
          selectedFilters.minBatteryCapacity = item[1];
        }

        if (item[0] === 'max_battery_capacity') {
          selectedFilters.maxBatteryCapacity = item[1];
        }

        if (item[0] === 'min_engine_power') {
          selectedFilters.minEnginePower = item[1];
        }

        if (item[0] === 'max_engine_power') {
          selectedFilters.maxEnginePower = item[1];
        }

        if (item[0] === 'min_clearance') {
          selectedFilters.minClearance = item[1];
        }

        if (item[0] === 'max_clearance') {
          selectedFilters.maxClearance = item[1];
        }

        if (item[0] === 'min_seats') {
          selectedFilters.minSeats = item[1];
        }

        if (item[0] === 'max_seats') {
          selectedFilters.maxSeats = item[1];
        }

        if (item[0] === 'min_doors') {
          selectedFilters.minDoors = item[1];
        }

        if (item[0] === 'max_doors') {
          selectedFilters.maxDoors = item[1];
        }

        if (item[0] === CarsStockFlag.SuperPrice.toLowerCase()) {
          selectedFilters.superPrice = item[0];
        }

        if (item[0] === CarsStockFlag.Approved.toLowerCase()) {
          selectedFilters.approved = item[0];
        }

        if (item[0] === CarsStockFlag.OnWarranty.toLowerCase()) {
          selectedFilters.guarantee = item[0];
        }

        if (item[0] === CarsStockFlag.PartnerCar.toLowerCase()) {
          selectedFilters.partnerCar = item[0];
        }

        if (item[0] === CarsStockFlag.Available.toLowerCase()) {
          selectedFilters.available = item[0];
        }

        if (item[0] === CarsStockFlag.OnStock.toLowerCase()) {
          selectedFilters.onStock = item[0];
        }

        if (item[0] === 'sort') {
          selectedFilters.sort = item[1];
        }

        if (item[0] === 'page') {
          selectedFilters.page = item[1];
        }
      });
    } else if (
      slug[0].split('--')[0]?.startsWith('sort') &&
      slug[0].split('--')[1]?.startsWith('page')
    ) {
      const splitPageAndSort = slug[0].split('--');

      selectedFilters.sort = splitPageAndSort[0].split('-')[1];
      selectedFilters.page = splitPageAndSort[1].split('-')[1];
    } else if (slug[0].split('--')[0]?.startsWith('sort')) {
      selectedFilters.sort = slug[0].split('-')[1];
    } else if (slug[0].split('--')[0]?.startsWith('page')) {
      selectedFilters.page = slug[0].split('-')[1];
    } else {
      if (slug[0]) {
        const newSlug = slug[0].split('--');
        selectedFilters.brand = [newSlug[0]];

        if (newSlug[1] && newSlug[1].startsWith('sort')) {
          selectedFilters.sort = newSlug[1].split('-')[1];
        } else if (newSlug[1] && newSlug[1].startsWith('page')) {
          selectedFilters.page = newSlug[1].split('-')[1];
        }

        if (newSlug[2]) {
          selectedFilters.page = newSlug[2].split('-')[1];
        }
      }

      if (slug[1]) {
        const newSlug = slug[1].split('--');
        selectedFilters.model = [newSlug[0]];

        if (newSlug[1] && newSlug[1].startsWith('sort')) {
          selectedFilters.sort = newSlug[1].split('-')[1];
        } else if (newSlug[1] && newSlug[1].startsWith('page')) {
          selectedFilters.page = newSlug[1].split('-')[1];
        }

        if (newSlug[2]) {
          selectedFilters.page = newSlug[2].split('-')[1];
        }
      }

      if (slug[2]) {
        const newSlug = slug[2].split('--');
        selectedFilters.complectation = [newSlug[0]];

        if (newSlug[1] && newSlug[1].startsWith('sort')) {
          selectedFilters.sort = newSlug[1].split('-')[1];
        } else if (newSlug[1] && newSlug[1].startsWith('page')) {
          selectedFilters.page = newSlug[1].split('-')[1];
        }

        if (newSlug[2]) {
          selectedFilters.page = newSlug[2].split('-')[1];
        }
      }
    }
  }

  return selectedFilters;
};

export const convertedSelectedFilters = (
  selectedItems: SelectedFilterFromUrlTypes,
  brandAndModels: CatalogStockBrandAndModelsResponse[],
  filtersParams: Nullable<CarsStockFiltersParamsResponse>
) => {
  const selectedCars = getSelectedCarsByUrl(selectedItems, brandAndModels);
  const convertedItems: ConvertedFilters = {
    brand: selectedCars.selectedBrands,
    model: selectedCars.selectedModels,
    complectation: selectedCars.selectedComplectations,
    gearboxes: [],
    bodies: [],
    wheels: [],
    colors: [],
    fuelTypes: [],
    locations: [],
    batteries: [],
    grades: [],
    vat: { label: '', value: '' },
    price: { min: '', max: '' },
    mileage: { min: '', max: '' },
    years: { min: '', max: '' },
    powerReserve: { min: '', max: '' },
    batteryCapacity: { min: '', max: '' },
    engineVolume: {
      min: { label: '', value: '' },
      max: { label: '', value: '' },
    },
    enginePower: { min: '', max: '' },
    clearance: { min: '', max: '' },
    seats: { min: '', max: '' },
    doors: { min: '', max: '' },
    electricEngines: [],
    specials: {
      superPrice: { label: '', value: '' },
      guarantee: { label: '', value: '' },
      approved: { label: '', value: '' },
      partnerCar: { label: '', value: '' },
      available: { label: '', value: '' },
      onStock: { label: '', value: '' },
    },
    page: 1,
    sort: { label: '', value: '' },
  };

  if (Object.keys(selectedItems).length) {
    if (selectedItems.gearboxes) {
      selectedItems.gearboxes.forEach((gearbox) => {
        const selectedGearboxes = filtersParams?.gearboxes.find(
          (item) => item.id.toLowerCase() === gearbox.toLowerCase()
        );

        if (selectedGearboxes) {
          convertedItems.gearboxes.push({
            label: selectedGearboxes.label,
            value: selectedGearboxes.id,
            title:
              selectedGearboxes.label !== GearboxNames.MANUAL
                ? GearboxNames.AUTOMAT
                : GearboxNames.MANUAL,
          });
        }
      });
    }

    if (selectedItems.bodies) {
      selectedItems.bodies.forEach((body) => {
        const selectedBodies = filtersParams?.bodies.find(
          (item) => item.id.toLowerCase() === body.toLowerCase()
        );

        if (selectedBodies) {
          convertedItems.bodies.push({
            label: selectedBodies.label,
            value: selectedBodies.id,
          });
        }
      });
    }

    if (selectedItems.wheels) {
      selectedItems.wheels.forEach((wheel) => {
        const selectedWheels = filtersParams?.wheels.find(
          (item) => item.id.toLowerCase() === wheel.toLowerCase()
        );

        if (selectedWheels) {
          convertedItems.wheels.push({
            label: selectedWheels.label,
            value: selectedWheels.id,
          });
        }
      });
    }

    if (selectedItems.colors) {
      selectedItems.colors.forEach((color) => {
        const selectedColors = filtersParams?.colors.find(
          (item) => String(item.id) === color.toLowerCase()
        );

        if (selectedColors) {
          convertedItems.colors.push({
            hex: selectedColors.hex,
            label: selectedColors.label,
            value: String(selectedColors.id),
          });
        }
      });
    }

    if (selectedItems.fuelTypes) {
      selectedItems.fuelTypes.forEach((fuel) => {
        const selectedFuelTypes = filtersParams?.fuelTypes.find(
          (item) => item.id.toLowerCase() === fuel.toLowerCase()
        );

        if (selectedFuelTypes) {
          convertedItems.fuelTypes.push({
            label: selectedFuelTypes.label,
            value: selectedFuelTypes.id,
          });
        }
      });
    }

    if (selectedItems.locations) {
      selectedItems.locations.forEach((location) => {
        const selectedLocations = filtersParams?.locations.find(
          (item) => String(item.id) === location.toLowerCase()
        );

        if (selectedLocations) {
          convertedItems.locations.push({
            label: selectedLocations.label,
            value: String(selectedLocations.id),
          });
        }
      });
    }

    if (selectedItems.batteries) {
      selectedItems.batteries.forEach((battery) => {
        const selectedBatteries = filtersParams?.batteryTypes.find(
          (item) => item.id.toLowerCase() === battery.toLowerCase()
        );

        if (selectedBatteries) {
          convertedItems.batteries.push({
            label: selectedBatteries.label,
            value: selectedBatteries.id,
          });
        }
      });
    }

    if (selectedItems.electricEngines) {
      selectedItems.electricEngines.forEach((electricEngine) => {
        const selectedElectricEngines = filtersParams?.electricEngines.find(
          (item) => item === Number(electricEngine)
        );

        if (selectedElectricEngines) {
          convertedItems.electricEngines.push({
            label: String(selectedElectricEngines),
            value: String(selectedElectricEngines),
          });
        }
      });
    }

    if (selectedItems.grades) {
      selectedItems.grades.forEach((grade) => {
        const selectedGrades = filtersParams?.grades.find(
          (item) => item.id.toLowerCase() === grade.toLowerCase()
        );

        if (selectedGrades) {
          convertedItems.grades.push({
            label: selectedGrades.label,
            value: selectedGrades.id,
          });
        }
      });
    }

    if (selectedItems.vat) {
      const selectedVat = filtersParams?.vats.find(
        (item) => item.id.toLowerCase() === selectedItems.vat?.toLowerCase()
      );

      if (selectedVat) {
        convertedItems.vat = {
          label: selectedVat.label,
          value: selectedVat.id,
        };
      }
    }

    if (selectedItems.minPrice) {
      convertedItems.price.min = selectedItems.minPrice;
    }

    if (selectedItems.maxPrice) {
      convertedItems.price.max = selectedItems.maxPrice;
    }

    if (selectedItems.minMileage) {
      convertedItems.mileage.min = selectedItems.minMileage;
    }

    if (selectedItems.maxMileage) {
      convertedItems.mileage.max = selectedItems.maxMileage;
    }

    if (selectedItems.minYear) {
      convertedItems.years.min = selectedItems.minYear;
    }

    if (selectedItems.maxYear) {
      convertedItems.years.max = selectedItems.maxYear;
    }

    if (selectedItems.minPowerReserve) {
      convertedItems.powerReserve.min = selectedItems.minPowerReserve;
    }

    if (selectedItems.maxPowerReserve) {
      convertedItems.powerReserve.max = selectedItems.maxPowerReserve;
    }

    if (selectedItems.minBatteryCapacity) {
      convertedItems.batteryCapacity.min = selectedItems.minBatteryCapacity;
    }

    if (selectedItems.maxBatteryCapacity) {
      convertedItems.batteryCapacity.max = selectedItems.maxBatteryCapacity;
    }

    if (selectedItems.minEnginePower) {
      convertedItems.enginePower.min = selectedItems.minEnginePower;
    }

    if (selectedItems.maxEnginePower) {
      convertedItems.enginePower.max = selectedItems.maxEnginePower;
    }

    if (selectedItems.minClearance) {
      convertedItems.clearance.min = selectedItems.minClearance;
    }

    if (selectedItems.maxClearance) {
      convertedItems.clearance.max = selectedItems.maxClearance;
    }

    if (selectedItems.minSeats) {
      convertedItems.seats.min = selectedItems.minSeats;
    }

    if (selectedItems.maxSeats) {
      convertedItems.seats.max = selectedItems.maxSeats;
    }

    if (selectedItems.minDoors) {
      convertedItems.doors.min = selectedItems.minDoors;
    }

    if (selectedItems.maxDoors) {
      convertedItems.doors.max = selectedItems.maxDoors;
    }

    if (selectedItems.minEngineVolume) {
      convertedItems.engineVolume.min = {
        label: selectedItems.minEngineVolume,
        value: selectedItems.minEngineVolume,
      };
    }

    if (selectedItems.maxEngineVolume) {
      convertedItems.engineVolume.max = {
        label: selectedItems.maxEngineVolume,
        value: selectedItems.maxEngineVolume,
      };
    }

    if (selectedItems.approved) {
      const selectedApproved = filtersParams?.flags.find(
        (item) => item.id.toLowerCase() === selectedItems.approved
      );

      if (selectedApproved) {
        convertedItems.specials.approved = {
          label: selectedApproved.label,
          value: selectedApproved.id,
        };
      }
    }

    if (selectedItems.superPrice) {
      const selectedSuperPrice = filtersParams?.flags.find(
        (item) => item.id.toLowerCase() === selectedItems.superPrice
      );

      if (selectedSuperPrice) {
        convertedItems.specials.superPrice = {
          label: selectedSuperPrice.label,
          value: selectedSuperPrice.id,
        };
      }
    }

    if (selectedItems.guarantee) {
      const selectedGuarantee = filtersParams?.flags.find(
        (item) => item.id.toLowerCase() === selectedItems.guarantee
      );

      if (selectedGuarantee) {
        convertedItems.specials.guarantee = {
          label: selectedGuarantee.label,
          value: selectedGuarantee.id,
        };
      }
    }

    if (selectedItems.partnerCar) {
      const selectedPartnerCar = filtersParams?.flags.find(
        (item) => item.id.toLowerCase() === selectedItems.partnerCar
      );

      if (selectedPartnerCar) {
        convertedItems.specials.partnerCar = {
          label: selectedPartnerCar.label,
          value: selectedPartnerCar.id,
        };
      }
    }

    if (selectedItems.available) {
      const selectedAvailable = filtersParams?.flags.find(
        (item) => item.id.toLowerCase() === selectedItems.available
      );

      if (selectedAvailable) {
        convertedItems.specials.available = {
          label: selectedAvailable.label,
          value: selectedAvailable.id,
        };
      }
    }

    if (selectedItems.onStock) {
      const selectedOnStock = filtersParams?.flags.find(
        (item) => item.id.toLowerCase() === selectedItems.onStock
      );

      if (selectedOnStock) {
        convertedItems.specials.onStock = {
          label: selectedOnStock.label,
          value: selectedOnStock.id,
        };
      }
    }

    if (selectedItems.page) {
      convertedItems.page = +selectedItems.page;
    }

    if (selectedItems.sort) {
      const selectedSort = sortingData.find(
        (sort) => sort.value === selectedItems.sort
      );

      if (selectedSort) {
        convertedItems.sort = selectedSort;
      }
    }
  }

  return convertedItems;
};

export const getSelectedCarsByUrl = (
  selectedItems: SelectedFilterFromUrlTypes,
  brandAndModels: CatalogStockBrandAndModelsResponse[]
): SelectedCarsType => {
  const convertedItems: SelectedCarsType = {
    selectedBrands: [],
    selectedModels: [],
    selectedComplectations: [],
  };

  if (Object.keys(selectedItems).length) {
    if (selectedItems.brand) {
      selectedItems.brand.forEach((brand) => {
        const selectedBrand = brandAndModels.find(
          (item) => item.alias === brand
        );

        if (selectedBrand) {
          convertedItems.selectedBrands.push({
            label: selectedBrand.name,
            value: String(selectedBrand.id),
          });

          if (selectedItems.model) {
            selectedItems.model.forEach((model) => {
              const selectedModels = brandAndModels
                .filter((brand) => brand.id === selectedBrand.id)
                .map((item) =>
                  item.models.map((model) => {
                    return {
                      title: item.name,
                      label: model.name,
                      value: model.id,
                      alias: model.alias,
                    };
                  })
                )
                .flat(1)
                .find((item) => item.alias === model);

              if (selectedModels) {
                convertedItems.selectedModels.push({
                  label: selectedModels.label,
                  value: String(selectedModels.value),
                  title: selectedModels.title,
                });

                if (selectedItems.complectation) {
                  selectedItems.complectation.forEach((complectation) => {
                    const selectedComplectation = brandAndModels
                      .filter((brand) => brand.id === selectedBrand.id)
                      .map((brand) =>
                        brand.models
                          .filter((model) => model.id === selectedModels.value)
                          .map((model) =>
                            model.complectations?.map((complectation) => {
                              return {
                                label: complectation.name,
                                value: complectation.id,
                                title: model.name,
                                alias: complectation.alias,
                              };
                            })
                          )
                      )
                      .flat(2)
                      .find((item) => item?.alias === complectation);

                    if (selectedComplectation) {
                      convertedItems.selectedComplectations.push({
                        label: selectedComplectation.label,
                        value: String(selectedComplectation.value),
                        title: selectedComplectation.title,
                      });
                    }
                  });
                }
              }
            });
          }
        }
      });
    }
  }

  return convertedItems;
};

export const handleDeleteAllFilters = (
  dispatch: StoreDispatch,
  brandsAndModels: CatalogStockBrandAndModelsResponse[]
) => {
  brandsAndModels.length > 1 && dispatch(deleteCarBrands());
  dispatch(deleteCarModels());
  dispatch(deleteCarComplectations());
  dispatch(setCarBodies([]));
  dispatch(deleteCarGearbox());
  dispatch(setCarPrice({ min: '', max: '' }));
  dispatch(setCarWheel([]));
  dispatch(setCarColors([]));
  dispatch(setCarEngineTypes([]));
  dispatch(
    setCarEngineCapacity({
      min: { value: '', label: '' },
      max: { value: '', label: '' },
    })
  );
  dispatch(setCarYear({ min: '', max: '' }));
  dispatch(setCarMileage({ min: '', max: '' }));
  dispatch(setCarLocations([]));
  dispatch(setCarVat({ value: '', label: '' }));
  dispatch(setCarSuperPrice({ value: '', label: '' }));
  dispatch(setCarPowerReserve({ min: '', max: '' }));
  dispatch(setCarBattery([]));
  dispatch(setCarGrades([]));
  dispatch(setCarBatteryCapacity({ min: '', max: '' }));
  dispatch(setCarEnginePower({ min: '', max: '' }));
  dispatch(setCarClearance({ min: '', max: '' }));
  dispatch(setCarSeats({ min: '', max: '' }));
  dispatch(setCarDoors({ min: '', max: '' }));
  dispatch(setCarElectricEngines([]));
  dispatch(setCarApproved({ value: '', label: '' }));
  dispatch(setCarGuarantee({ value: '', label: '' }));
  dispatch(setCarPartnerCar({ value: '', label: '' }));
  dispatch(setCarAvailable({ value: '', label: '' }));
  dispatch(setCarOnStock({ value: '', label: '' }));
  dispatch(setPage(1));
};

export const getSEOType = (
  carType: CarsStockType,
  selectedFilters: SelectedFilterFromUrlTypes
): SEOTemplatesTypes => {
  const filters = cloneDeep(selectedFilters);

  delete filters.sort;
  delete filters.page;

  const filtersValue = Object.values(filters).flat(1);

  if (
    (filtersValue.length === 1 && filters.brand) ||
    (filtersValue.length === 2 && filters.brand && filters.model) ||
    (filtersValue.length === 3 &&
      filters.brand &&
      filters.model &&
      filters.complectation)
  ) {
    switch (carType) {
      case 'new':
        return 'stock_new_name';
      case 'amp':
        return 'stock_amp_name';
      case 'electric':
        return 'stock_electric_name';
      case 'commercial':
        return 'stock_commercial_name';
      case 'all':
        return 'stock_all_name';
    }
  } else if (filtersValue.length === 1 && filters.bodies) {
    switch (carType) {
      case 'new':
        return 'stock_new_body';
      case 'amp':
        return 'stock_amp_body';
      case 'electric':
        return 'stock_electric_body';
      case 'commercial':
        return 'stock_commercial_body';
      case 'all':
        return 'stock_all_body';
    }
  } else if (filtersValue.length === 1 && filters.gearboxes) {
    switch (carType) {
      case 'new':
        return 'stock_new_gearbox';
      case 'amp':
        return 'stock_amp_gearbox';
      case 'commercial':
        return 'stock_commercial_gearbox';
      case 'all':
        return 'stock_all_gearbox';
    }
  } else if (
    (filtersValue.length === 1 || filtersValue.length === 2) &&
    (filters.minYear || filters.maxYear)
  ) {
    switch (carType) {
      case 'amp':
        return 'stock_amp_year';
    }
  } else if (filtersValue.length === 1 && filters.fuelTypes) {
    switch (carType) {
      case 'new':
        return 'stock_new_fuel';
      case 'amp':
        return 'stock_amp_fuel';
      case 'commercial':
        return 'stock_commercial_fuel';
      case 'all':
        return 'stock_all_fuel';
    }
  }

  switch (carType) {
    case 'new':
      return 'stock_new';
    case 'amp':
      return 'stock_amp';
    case 'electric':
      return 'stock_electric';
    case 'commercial':
      return 'stock_commercial';
    case 'all':
      return 'stock_all';
  }
};

export const convertSeoField = (
  item: string,
  selectedFilter: SelectedFiltersType,
  filterParams: Nullable<CarsStockFiltersParamsResponse>
) => {
  if (!filterParams) {
    return '';
  }

  let year = '';

  if (
    selectedFilter.selectedParams.selectedYear.min ||
    selectedFilter.selectedParams.selectedYear.max
  ) {
    if (!selectedFilter.selectedParams.selectedYear.max) {
      year = `${selectedFilter.selectedParams.selectedYear.min}`;
    } else if (!selectedFilter.selectedParams.selectedYear.min) {
      year = `${selectedFilter.selectedParams.selectedYear.max}`;
    } else if (
      selectedFilter.selectedParams.selectedYear.min ===
      selectedFilter.selectedParams.selectedYear.max
    ) {
      year = `${selectedFilter.selectedParams.selectedYear.min}`;
    } else {
      year = `от ${selectedFilter.selectedParams.selectedYear.min} до ${selectedFilter.selectedParams.selectedYear.max}`;
    }
  }

  const name = [
    selectedFilter.selectedCars.selectedBrands[0]?.label,
    selectedFilter.selectedCars.selectedModels[0]?.label ?? undefined,
    selectedFilter.selectedCars.selectedComplectations[0]?.label ?? undefined,
  ]
    .filter(isNotNullish)
    .join(' ');

  const body = selectedFilter.selectedParams.selectedBodies[0]?.label ?? '';
  const gearbox = selectedFilter.selectedParams.selectedGearbox[0]?.label ?? '';
  const foundGearbox = filterParams.gearboxes.find(
    (item) => item.label === gearbox
  );
  const gearboxCase = foundGearbox?.labelCase ?? '';
  const gearboxShort = foundGearbox?.labelShort ?? '';
  const fuel = selectedFilter.selectedParams.selectedEngineType[0]?.label ?? '';
  const fuelCase =
    filterParams.fuelTypes.find((item) => item.label === fuel)?.labelCase ?? '';

  return item
    .replaceAll('{{name}}', name)
    .replaceAll('{{name|lowercase}}', name.toLowerCase())
    .replaceAll('{{body}}', body)
    .replaceAll('{{body|lowercase}}', body.toLowerCase())
    .replaceAll('{{year}}', year)
    .replaceAll('{{gearbox}}', gearbox)
    .replaceAll('{{gearbox|lowercase}}', gearbox.toLowerCase())
    .replaceAll('{{gearbox_case}}', gearboxCase)
    .replaceAll('{{gearbox_case|lowercase}}', gearboxCase.toLowerCase())
    .replaceAll('{{gearbox_short}}', gearboxShort)
    .replaceAll('{{gearbox_short|lowercase}}', gearboxShort.toLowerCase())
    .replaceAll('{{fuel}}', fuel)
    .replaceAll('{{fuel|lowercase}}', fuel.toLowerCase())
    .replaceAll('{{fuel_case}}', fuelCase)
    .replaceAll('{{fuel_case|lowercase}}', fuelCase.toLowerCase());
};

const getBodyIcon = (value: CarsStockBody) => {
  switch (value) {
    case CarsStockBody.Hatchback:
      return <Hatchback />;
    case CarsStockBody.Sedan:
      return <Sedan />;
    case CarsStockBody.StationWagon:
      return <Wagon />;
    case CarsStockBody.Coupe:
      return <Coupe />;
    case CarsStockBody.Minivan:
      return <Minivan />;
    case CarsStockBody.Van:
      return <Van />;
    case CarsStockBody.Pickup:
      return <Pickup />;
    case CarsStockBody.Suv:
      return <Suv />;
    case CarsStockBody.Crossover:
      return <Crossover />;
    case CarsStockBody.Minibus:
      return <Minibus />;
    case CarsStockBody.LiftBack:
      return <Liftback />;
    case CarsStockBody.Chassis:
      return <Chassis />;
    case CarsStockBody.Bus:
      return <Minibus />;
    case CarsStockBody.Cabriolet:
      return <Cabriolet />;
    case CarsStockBody.ShootingBrake:
      return <ShootingBrake />;
    default:
      return <Crossover />;
  }
};

export const getBannerPosition = (row: number, size: number): number => {
  if (size > 2) {
    return 0;
  }

  return row * 4 - 4 + (size === 1 ? 3 : 2);
};

export const createBrandsAndModelsPayload = (
  selectedCars: SelectedCarsType,
  activeBrands?: string[],
  activeModels?: string[]
): CarsStockFiltersParams => {
  const filterPayload: CarsStockFiltersParams = {};

  if (selectedCars.selectedBrands.length) {
    filterPayload.brand = selectedCars.selectedBrands
      .map((item) => item.value)
      .join(',');
  } else if (activeBrands && activeBrands.length !== 0) {
    filterPayload.brand = activeBrands.join(',');
  }

  if (selectedCars.selectedModels.length) {
    filterPayload.model = selectedCars.selectedModels
      .map((item) => item.value)
      .join(',');
  } else if (activeModels && activeModels.length !== 0) {
    filterPayload.model = activeModels.join(',');
  }

  if (selectedCars.selectedComplectations.length) {
    filterPayload.complectation = selectedCars.selectedComplectations
      .map((item) => item.value)
      .join(',');
  }

  return filterPayload;
};

export const handleSetSelectedFiltersFromUrl = (
  dispatch: StoreDispatch,
  filters: ConvertedFilters
): void => {
  filters.brand.length > 0 && dispatch(setCarBrandsArray(filters.brand));
  filters.model.length > 0 && dispatch(setCarModelsArray(filters.model));
  filters.complectation.length > 0 &&
    dispatch(setCarComplectationsArray(filters.complectation));
  filters.gearboxes.length > 0 &&
    dispatch(setCarGearboxArray(filters.gearboxes));
  filters.bodies.length > 0 && dispatch(setCarBodies(filters.bodies));
  filters.wheels.length > 0 && dispatch(setCarWheel(filters.wheels));
  filters.colors.length > 0 && dispatch(setCarColors(filters.colors));
  filters.locations.length > 0 && dispatch(setCarLocations(filters.locations));
  filters.fuelTypes.length > 0 &&
    dispatch(setCarEngineTypes(filters.fuelTypes));
  filters.batteries.length > 0 && dispatch(setCarBattery(filters.batteries));
  filters.electricEngines.length > 0 &&
    dispatch(setCarElectricEngines(filters.electricEngines));
  filters.grades.length > 0 && dispatch(setCarGrades(filters.grades));
  (filters.price.min || filters.price.max) &&
    dispatch(setCarPrice(filters.price));
  (filters.mileage.min || filters.mileage.max) &&
    dispatch(setCarMileage(filters.mileage));
  (filters.years.min || filters.years.max) &&
    dispatch(setCarYear(filters.years));
  (filters.powerReserve.min || filters.powerReserve.max) &&
    dispatch(setCarPowerReserve(filters.powerReserve));
  (filters.batteryCapacity.min || filters.batteryCapacity.max) &&
    dispatch(setCarBatteryCapacity(filters.batteryCapacity));
  (filters.enginePower.min || filters.enginePower.max) &&
    dispatch(setCarEnginePower(filters.enginePower));
  (filters.clearance.min || filters.clearance.max) &&
    dispatch(setCarClearance(filters.clearance));
  (filters.seats.min || filters.seats.max) &&
    dispatch(setCarSeats(filters.seats));
  (filters.doors.min || filters.doors.max) &&
    dispatch(setCarDoors(filters.doors));
  (filters.engineVolume.min.value || filters.engineVolume.max.value) &&
    dispatch(setCarEngineCapacity(filters.engineVolume));
  filters.specials.superPrice.value &&
    dispatch(setCarSuperPrice(filters.specials.superPrice));
  filters.specials.guarantee.value &&
    dispatch(setCarGuarantee(filters.specials.guarantee));
  filters.specials.approved.value &&
    dispatch(setCarApproved(filters.specials.approved));
  filters.specials.partnerCar.value &&
    dispatch(setCarPartnerCar(filters.specials.partnerCar));
  filters.specials.available.value &&
    dispatch(setCarAvailable(filters.specials.available));
  filters.specials.onStock.value &&
    dispatch(setCarOnStock(filters.specials.onStock));
  filters.vat.value && dispatch(setCarVat(filters.vat));
  filters.page !== 1 && dispatch(setPage(filters.page));
  filters.sort.value && dispatch(setCurrentSort(filters.sort));
};

export const setActiveBrandsAndModelsFromSettings = (
  dispatch: StoreDispatch,
  activeBrands: string[],
  activeModels: string[],
  brandsAndModels: CatalogStockBrandAndModelsResponse[]
) => {
  if (activeBrands && activeBrands.length !== 0) {
    const filteredBrandsAndModels = brandsAndModels
      .filter((brand) => activeBrands.includes(String(brand.id)))
      .map((brand) => ({
        ...brand,
        models:
          activeModels && activeModels.length > 0
            ? brand.models.filter((model) =>
                activeModels.includes(String(model.id))
              )
            : brand.models,
      }));

    dispatch(setBrandsAndModelsSuccess(filteredBrandsAndModels));
  }

  if (activeBrands.length === 1) {
    const oneBrand = brandsAndModels.find(
      (item) => String(item.id) === activeBrands[0]
    );

    if (oneBrand) {
      dispatch(
        setCarBrand({ value: String(oneBrand.id), label: oneBrand.name })
      );
    }
  }
};

export const isWrongPagePath = (
  brandsAndModels: CatalogStockBrandAndModelsResponse[],
  selectedItems: SelectedFilterFromUrlTypes
): boolean => {
  if (selectedItems.brand && selectedItems.brand.length === 1) {
    const validBrandIds = brandsAndModels.map((item) => item.alias);

    if (!validBrandIds.includes(selectedItems.brand[0])) {
      return true;
    }

    if (selectedItems.model && selectedItems.model.length === 1) {
      const foundBrandsDataByBrand = brandsAndModels.find(
        (item) =>
          item.alias === (selectedItems.brand ? selectedItems.brand[0] : '')
      );

      if (foundBrandsDataByBrand) {
        const validModelIds = foundBrandsDataByBrand.models.map(
          (item) => item.alias
        );

        if (!validModelIds.includes(selectedItems.model[0])) {
          return true;
        }

        if (
          selectedItems.complectation &&
          selectedItems.complectation.length === 1
        ) {
          const foundBrandsDataByModel = foundBrandsDataByBrand.models.find(
            (item) =>
              item.alias === (selectedItems.model ? selectedItems.model[0] : '')
          );

          if (foundBrandsDataByModel) {
            const validComplectationIds =
              foundBrandsDataByModel?.complectations?.map(
                (item) => item.alias
              ) ?? [];

            if (
              !validComplectationIds.includes(selectedItems.complectation[0])
            ) {
              return true;
            }
          }
        }
      }
    }
  }

  return false;
};

export const getStockCarsMinAndMaxPrices = (cars: CarStockItemResponse[]) => {
  const prices = cars.reduce<number[]>((acc, currentValue) => {
    const price = currentValue.discountPrice
      ? currentValue.discountPrice
      : currentValue.price;
    const isPriceHidden = currentValue.priceHidden;

    if (price && !isPriceHidden) {
      return [...acc, price];
    } else {
      return [...acc];
    }
  }, []);

  if (prices.length === 0) {
    return { minPrice: 0, maxPrice: 0 };
  }

  return { minPrice: Math.min(...prices), maxPrice: Math.max(...prices) };
};

export const getCatalogStockModelsIdsByBrandId = (
  searchBrandId: number,
  brandAndModels: CatalogStockBrandAndModelsResponse[]
): number[] => {
  return (
    brandAndModels
      .find((brand) => brand.id === searchBrandId)
      ?.models.map((model) => model.id) ?? []
  );
};

export const getDefaultCatalogFilter = (
  carsType: CarsStockType,
  activeBrands: string[],
  activeModels: string[],
  selectedCars: SelectedCarsType,
  brandsAndModels: CatalogStockBrandAndModelsResponse[],
  catalogFilter: SelectedFiltersCatalogPayload[]
): SelectedFiltersCatalogPayload[] => {
  if (carsType === 'new') {
    const getCatalogFilterModels = (brand: number) => {
      return activeModels && activeModels.length !== 0
        ? activeModels
            .filter((activeModel) =>
              getCatalogStockModelsIdsByBrandId(
                brand,
                brandsAndModels
              ).includes(Number(activeModel))
            )
            .map((activeModel) => ({ id: Number(activeModel) }))
        : [];
    };

    if (
      activeBrands &&
      activeBrands.length !== 0 &&
      selectedCars.selectedBrands.length === 0
    ) {
      return activeBrands.map((brand) => ({
        brand: Number(brand),
        models: getCatalogFilterModels(Number(brand)),
      }));
    } else if (
      activeModels &&
      activeModels.length !== 0 &&
      selectedCars.selectedModels.length === 0
    ) {
      return selectedCars.selectedBrands.map((brand) => ({
        brand: Number(brand.value),
        models: getCatalogFilterModels(Number(brand.value)),
      }));
    }
  }

  return catalogFilter;
};

export const isCarComparing = (
  comparisonCars: ComparisonCarsType[],
  carId: number
): boolean => {
  return !!comparisonCars.find((comparisonCar) => comparisonCar.id === carId);
};

export const getComparisonCarsLink = (
  comparisonCars: ComparisonCarsType[]
): string => {
  return `/cars/compare/${comparisonCars
    .map((comparisonCars) => comparisonCars.id + comparisonCars.carType)
    .join('-')}`;
};
