import React from 'react';
import styled, { css } from 'styled-components';
import { useSelect } from 'downshift';

import ArrowIcon from '@/assets/svg/arrow-down.svg';
import { ScrollStyles, TextLimit } from '@/components/styles';
import { colors } from '@/constants/theme';

import { SelectProps } from './Select.types';

function Select({
  label,
  value,
  options,
  onChange,
  className,
  isRequired,
  errorMessage,
}: SelectProps) {
  const {
    isOpen,
    selectedItem,
    getToggleButtonProps,
    getMenuProps,
    getItemProps,
  } = useSelect({
    selectedItem: value,
    items: options,
    onSelectedItemChange: ({ selectedItem }) => {
      onChange?.(selectedItem ?? options[0]);
    },
  });

  return (
    <Component className={className}>
      <Display
        type="button"
        invalid={!!errorMessage}
        {...getToggleButtonProps()}
      >
        <Label isSelectedItem={!!value.label}>
          {selectedItem?.label || (
            <>
              {label} {isRequired && <Required>*</Required>}
            </>
          )}
        </Label>
        <StyledArrowIcon isOpen={isOpen} />
      </Display>
      <DropdownMenu {...getMenuProps()} isOpen={isOpen}>
        {options.length > 0 ? (
          <OptionList>
            {options.map((option, index) => (
              <OptionItem
                key={`${option.value}${index}`}
                {...getItemProps({
                  item: option,
                  index,
                  isSelected: option.value === value?.value,
                })}
              >
                {option.label}
              </OptionItem>
            ))}
          </OptionList>
        ) : (
          <NoOptions>No options</NoOptions>
        )}
      </DropdownMenu>

      {errorMessage && <Error>{errorMessage}</Error>}
    </Component>
  );
}

export default Select;

const Component = styled.div`
  position: relative;
  width: 100%;
`;

const Display = styled.button<{ disabled?: boolean; invalid?: boolean }>`
  z-index: 4;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 14px 16px;
  height: 50px;
  background: ${(props) => props.theme.white_1};
  border: 1px solid ${(props) => props.theme.gray1800};
  border-radius: 5px;

  ${({ disabled }) =>
    disabled &&
    css`
      cursor: default;
    `}

  ${({ invalid }) =>
    invalid &&
    css`
      border: 1px solid ${colors.red700};
      background: ${(props) => props.theme.red_1};
    `}
`;

const Label = styled.span<{ isSelectedItem: boolean }>`
  font-weight: 300;
  font-size: 14px;
  line-height: 20px;
  text-align: left;
  color: ${(props) => props.theme.black100_alpha50};

  ${TextLimit};

  ${({ isSelectedItem }) =>
    isSelectedItem &&
    css`
      font-weight: 500;
      color: ${(props) => props.theme.black100};
    `}
`;

const Required = styled.span`
  color: ${colors.red600} !important;
`;

const DropdownMenu = styled.div<{ isOpen: boolean }>`
  z-index: 3;
  position: absolute;
  top: 52px;
  width: 100%;
  background: ${(props) => props.theme.white_1};
  border: 1px solid ${(props) => props.theme.gray1800};
  box-shadow: ${(props) => props.theme.blackShadow100};
  border-radius: 5px;
  visibility: hidden;
  opacity: 0;
  pointer-events: none;
  max-height: 320px;

  ${ScrollStyles}

  ${({ isOpen }) =>
    isOpen &&
    css`
      visibility: visible;
      opacity: 1;
      pointer-events: auto;
    `};
`;

const StyledArrowIcon = styled(ArrowIcon)<{ isOpen: boolean }>`
  flex: 0 0 14px;
  max-width: 14px;
  height: 8px;
  opacity: 0.4;
  transition: 0.3s;

  path {
    fill: ${(props) => props.theme.black100};
  }

  ${({ isOpen }) =>
    isOpen &&
    css`
      transform: rotate(180deg);
    `};
`;

const OptionList = styled.ul`
  padding: 15px 0;
`;

const OptionItem = styled.li<{ isSelected: boolean }>`
  padding: 5px 15px;

  cursor: pointer;
  font-weight: 300;
  font-size: 14px;
  line-height: 16px;
  color: ${(props) => props.theme.black100};

  ${({ isSelected }) =>
    isSelected &&
    css`
      font-weight: 500;
    `};
`;

const NoOptions = styled.div``;

const Error = styled.div`
  position: absolute;
  color: ${colors.red600};
  font-weight: 300;
  font-size: 13px;
  line-height: 17px;
  bottom: -18px;
`;
